import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from '../../axios';

import * as actions from '../../store/actions';

class Redirects extends React.Component {
    state = {
        loading: false,
        error: null,
        contents: [],
        inputs: {
            sourceUrl: "",
            redirectUrl: "",
        }
    }

    inputChangeHandler = (name, val) => {
        let inputs = { ...this.state.inputs, [name]: val };

        this.setState({
            inputs: inputs
        });
    }

    componentDidMount() {

        if (this.props.activeDomain.id !== null) {
            this.getRedirectedUrls();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && this.props.activeDomain.id !== prevProps.activeDomain.id) {
            this.getRedirectedUrls();
        }
    }



    getRedirectedUrls() {
        this.setState({
            loading: true,
        });
        axios.post("redirects/get-urls", {
            domainID: this.props.activeDomain.id
        })
            .then(response => {
                this.setState({
                    loading: false,
                    contents: response.data,
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }

    submitForm = () => {
        this.setState({
            loading: true,
        });

        const postData = this.state.inputs;
        postData.domainID = this.props.activeDomain.id;

        axios.post("redirects/add-url", postData)
            .then(response => {
                this.setState({
                    inputs: {
                        sourceUrl: "",
                        redirectUrl: "",
                    }
                })
                this.getRedirectedUrls();
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }


    errorCloseHandler = () => {
        this.setState({
            error: null,
        });
    }

    render() {
        let loading = null;

        if (this.state.loading) {
            loading = <Loading size={100} />;
        }

        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        let tabContent;
        let contents = null;

        if (this.state.contents.length > 0) {
            contents = this.state.contents.map(content => {
                return (
                    <TableRow key={content.id}>
                        <TableCell><a rel="noopener noreferrer" href={content.source_url} target="_blank">{content.source_url}</a></TableCell>
                        <TableCell><a rel="noopener noreferrer" href={content.redirect_url} target="_blank">{content.redirect_url}</a></TableCell>

                    </TableRow>
                )
            });
        }
        tabContent = (
            <React.Fragment>
                <Paper square className="GeneralPaper " >

                    <Table className="ExoclickTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Kaynak Url</TableCell>
                                <TableCell>Yönlendirildiği Url</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        );
        return (
            <Grid container spacing={24}>

                {loading}
                {error}
                <Grid item xs={12} sm={12} lg={12}>
                    <Grid container spacing={24}>

                        <Grid item xs={12} sm={12} xl={12} >
                            <Paper className="GeneralPaper" elevation={1} square>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={12} xl={5} >


                                        <TextField
                                            fullWidth
                                            label="Kaynak Link"
                                            value={this.state.inputs.sourceUrl}
                                            margin="normal"
                                            onChange={(e) => this.inputChangeHandler("sourceUrl", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={5} >


                                        <TextField
                                            fullWidth
                                            label="Yönleneceği Link"
                                            value={this.state.inputs.redirectUrl}
                                            margin="normal"
                                            onChange={(e) => this.inputChangeHandler("redirectUrl", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={2} >
                                        <Button style={{ display: "block", width: "100%", marginTop: "30px" }} onClick={this.submitForm} variant="contained" color="primary" >
                                            EKLE
                                        </Button>
                                    </Grid>
                                </Grid>



                            </Paper>



                        </Grid>
                        <Grid item xs={12} sm={12} xl={12} >
                            {tabContent}
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                </Grid>
            </Grid>
        );
    }
}



const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
        activeUser: state.auth.user
    }
}

const mapStateToDispatch = dispatch => {
    return {
        onChangeDomain: () => dispatch(actions.saveSettings()),
    }
}

export default connect(mapStateToProps, mapStateToDispatch)(Redirects); 