import React from 'react';
import Grid from '@material-ui/core/Grid';
import Check from "@material-ui/icons/Check";
import Visibility from "@material-ui/icons/Visibility";

const selectPhoto = (props) => {
    let checked = null;
    let checkButton = null;

    let classes = ['SelectPhoto'];
    if (props.checked) {
        classes.push("Checked");
        checked = <span className="CheckedSpan"><Check /></span>
    } else {
        checkButton = <Check onClick={props.checkClick} />;
    }

    let xl = 2;
    let lg = 3;

    switch (props.total) {
        case 1:
            xl = 12;
            lg = 12;
            break;
        case 2:
            xl = 6;
            lg = 6;
            break;
        case 3:
            xl = 4;
            lg = 4;
            break;
        case 4:
            xl = 3;
            lg = 3;
            break;
        case 5:
            xl = 3;
            lg = 3;
            break;
        default:
            xl = 2;
            lg = 3;
            break;
    }



    return (
        <Grid item xs={12} sm={lg} xl={xl} >
            <div className={classes.join(" ")}>
                <img src={props.img} alt="" />
                {checked}
                <span className="BackDrop"></span>
                <div className="Buttons">
                    {checkButton}
                    <Visibility onClick={props.showClick} />
                </div>
            </div>

        </Grid>
    );
}

export default selectPhoto;