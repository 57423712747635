import * as actionTypes from '../actions/actionTypes';


const initialState = {
    channels: [],
    filteredChannels: [],
    loading: null,
    error: null,
    pagination: {
        limit: 48,
        offset: 0,
        total: 0,
    },
    filters: {
        title: "",
        status: null,
        sort: null,
    },
    filtersExpanded: false,
}


const channels = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_CHANNELS:
            return {
                ...state,
                channels: action.channels,
                filteredChannels: action.channels,
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.channels.length,
                },
            }
        case actionTypes.SAVE_CHANNELS_START:

            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.CHANGE_CHANNEL_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    offset: action.offset,
                }
            }

        case actionTypes.SAVE_FILTERED_CHANNELS:

            return {
                ...state,
                filteredChannels: action.channels,
                pagination: {
                    ...state.pagination,
                    total: action.channels.length,
                    offset: 0,
                },
                filters: action.filters,
            }
        case actionTypes.RESET_ALL_CHANNELS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    title: "",
                    status: null,
                    sort: null,
                }
            }
        case actionTypes.CHANGE_CHANNEL_FILTERS_EXPANDED:
            return {
                ...state,
                filtersExpanded: action.expanded
            }
        default:
            return state;
    }
}

export default channels;