import axios from '../../axios';

import * as actionTypes from './actionTypes';
import * as actions from './index';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const auth = (email, password) => {
    return (dispatch) => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        }
        axios.post("/login", authData)
            .then(response => {
                const resData = response.data;
                if (resData.access_token) {
                    const token = resData.token_type + ' ' + resData.access_token;
                    const refresh_token = resData.refresh_token;

                    localStorage.setItem('token', token);
                    localStorage.setItem('refresh_token', refresh_token);

                    axios.defaults.headers.common['Authorization'] = token;
                    axios.get('user')
                        .then(userResponse => {
                            dispatch(authSuccess(token, refresh_token, userResponse.data));
                            dispatch(actions.saveDomains(userResponse.data.domains));
                            dispatch(actions.changeDefaultDomain(userResponse.data.domains[0].id, userResponse.data.domains[0].domain, userResponse.data.domains[0].site_name));
                        })
                } else {
                    dispatch(authFail());
                }
            })
    }
}

export const refreshToken = refreshToken => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            refreshToken
        }
        axios.post("/refresh-token", authData)
            .then(response => {
                const resData = response.data;
                if (resData.access_token) {
                    const token = resData.token_type + ' ' + resData.access_token;
                    const refresh_token = resData.refresh_token;

                    localStorage.setItem('token', token);
                    localStorage.setItem('refresh_token', refresh_token);

                    axios.defaults.headers.common['Authorization'] = token;
                    axios.get('user')
                        .then(userResponse => {
                            dispatch(authSuccess(token, refresh_token, userResponse.data));
                            dispatch(actions.saveDomains(userResponse.data.domains));
                            dispatch(actions.changeDefaultDomain(userResponse.data.domains[0].id, userResponse.data.domains[0].domain, userResponse.data.domains[0].site_name));
                        })
                } else {
                    dispatch(authWithTokenFail());
                }
            })
    }
}

export const authSuccess = (token, refresh_token, userData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        refresh_token: refresh_token,
        name: userData.name,
        email: userData.email,
        userID: userData.id
    }
}

export const authFail = () => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: "Kullanıcı bilgileri yanlış."
    }
}

export const authWithToken = token => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = token;
        axios.get('user')
            .then(userResponse => {
                dispatch(authSuccess(token, localStorage.refresh_token, userResponse.data));
                dispatch(actions.saveDomains(userResponse.data.domains));
                dispatch(actions.saveDomainWithLocalStorage());
            })
            .catch(err => {
                dispatch(refreshToken(localStorage.refresh_token));
            });
    }
}

export const authWithTokenFail = () => {
    return {
        type: actionTypes.AUTH_WITH_TOKEN_FAIL
    }
}

export const logout = () => {
    return dispatch => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        dispatch(logoutEnd());
    }
}

export const logoutEnd = () => {
    return {
        type: actionTypes.LOGOUT,
    }
}