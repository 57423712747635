import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "../../../axios";

import Edit from "@material-ui/icons/Edit";
import { Add } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import SpeakingUrl from "speakingurl";

class Categories extends React.Component {
    state = {
        loading: false,
        error: null,
        deleteDialog: false,
        addDialog: false,
        deleteID: null,
        editID: null,
        categories: [],
        addType: "add",
        category: {
            title: "",
            slug: "",
            differentSlug: false
        }
    };

    componentDidMount() {
        if (this.props.activeDomain.id !== null) {
            this.getCategories();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeDomain.id !== prevProps.activeDomain.id) {
            this.getCategories();
        }
    }

    titleChangeHandler = (name, value) => {
        this.inputChangeHandler("title", value);
        if (!this.state.category.differentSlug) {
            let slug = SpeakingUrl(value, {
                custom: {
                    "ö": "o",
                    "ü": "u"
                }
            });
            setTimeout(() => {
                this.inputChangeHandler("slug", slug);
            }, 5);
        }

    };

    slugChangeHandler = (name, value) => {
        this.inputChangeHandler(name, value);
        setTimeout(() => {
            this.inputChangeHandler("differentSlug", true);
        }, 5);
    };

    inputChangeHandler = (name, val) => {
        let category = { ...this.state.category, [name]: val };
        this.setState({
            category: category
        });
    };

    addCategory = () => {
        this.setState({
            loading: true
        });

        let url = "blog/category/add";
        const data = {
            domainID: this.props.activeDomain.id,
            name: this.state.category.title,
            slug: this.state.category.slug
        };
        if (this.state.addType === "edit") {
            url = "blog/category/update";
            data.category_id = this.state.editID;
        }

        axios.post(url, data)
            .then(response => {
                this.setState({
                    loading: false,
                    addDialog: false,
                    category: {
                        title: "",
                        slug: ""
                    }
                });
                this.getCategories();
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    getCategories() {
        this.setState({
            loading: true
        });

        axios.post("blog/category/get", {
            domainID: this.props.activeDomain.id
        })
            .then(response => {
                this.setState({
                    loading: false,
                    categories: response.data
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    }

    deleteCategory = () => {
        this.setState({
            loading: true
        });
        axios.post("blog/category/delete", {
            domainID: this.props.activeDomain.id,
            category_id: this.state.deleteID
        })
            .then(response => {
                this.setState({
                    loading: false,
                    deleteDialog: false
                });
                this.getCategories();
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };


    errorCloseHandler = () => {
        this.setState({
            error: null
        });
    };

    render() {
        let loading = null;

        if (this.state.loading) {
            loading = <Loading size={100} />;
        }

        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        "className": "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        let tabContent;
        let categories = null;

        if (this.state.categories.length > 0) {
            categories = this.state.categories.map(category => {
                return (
                    <TableRow key={category.id}>
                        <TableCell>{category.name}</TableCell>
                        <TableCell>{category.contentCount}</TableCell>
                        <TableCell>
                            <div style={{
                                display: "flex",
                                gap: "10px"
                            }}>
                                <Button variant="contained" color="primary" onClick={() => {
                                    this.setState({
                                        addDialog: true,
                                        addType: "edit",
                                        editID: category.id,
                                        category: {
                                            title: category.name,
                                            slug: category.slug,
                                            differentSlug: false
                                        }
                                    });
                                }}>
                                    <Edit />
                                </Button>

                                <Button variant="contained" color="secondary" onClick={() => {
                                    this.setState({
                                        deleteDialog: true,
                                        deleteID: category.id
                                    });
                                }}>
                                    <Delete />
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                );
            });
        }
        tabContent = (
            <React.Fragment>
                <Dialog
                    open={this.state.deleteDialog}
                    onClose={() => {
                        this.setState({
                            deleteDialog: false
                        });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth>
                    <DialogContent>
                        <p>Blog kategorisi silinecektir. </p>
                        <p><strong>Onaylıyor musunuz?</strong></p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                deleteDialog: false
                            });
                        }} color="primary">
                            İptal
                        </Button>
                        <Button onClick={this.deleteCategory} color="secondary" variant="contained">
                            SİL
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.addDialog}
                    onClose={() => {
                        this.setState({
                            addDialog: false
                        });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Başlık"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => this.titleChangeHandler("title", e.target.value)}
                            value={this.state.category.title}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Link Yapısı"
                            value={this.state.category.slug}
                            onChange={(e) => this.slugChangeHandler("slug", e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                addDialog: false
                            });
                        }} color="primary">
                            İptal
                        </Button>
                        <Button onClick={this.addCategory} color="primary" variant="contained">
                            {this.state.addType === "add" ? "EKLE" : "DÜZENLE"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Paper square className="GeneralPaper ">
                    <Button variant="contained" color="primary" onClick={() => {
                        this.setState({
                            addDialog: true,
                            addType: "add"
                        });
                    }}>
                        <Add /> Yeni Ekle
                    </Button>
                    <Table className="ExoclickTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Kategori</TableCell>
                                <TableCell>İçerik Sayısı</TableCell>
                                <TableCell>İşlem</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        );


        return (
            <Grid container spacing={24}>

                {loading}
                {error}
                <Grid item xs={12} sm={12} lg={12}>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} xl={12}>
                            {tabContent}
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                </Grid>
            </Grid>
        );
    }
}


const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
        activeUser: state.auth.user
    };
};

const mapStateToDispatch = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(Categories);