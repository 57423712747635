import * as actionTypes from '../actions/actionTypes';

const initialState = {
    configs: {},
    loading: null,
    error: null,
}

const configs = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_CONFIGS:
            return {
                ...state,
                configs: action.configs,
                loading: false,
                error: null,
            }
        case actionTypes.SAVE_CONFIGS_START:

            return {
                ...state,
                loading: true,
                error: null,
            }

        default:
            return state;
    }
}

export default configs;