import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const savePornstars = () => {
    return (dispatch, getState) => {
        dispatch(savePornstarsStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
        }
        axios.post("pornstar/getPornstars", postData)
            .then(response => {
                dispatch(savePornstarsToState(response.data));
            })
    }
}

export const savePornstarsToState = (pornstars) => {
    return {
        type: actionTypes.SAVE_PORNSTARS,
        pornstars: pornstars,
    }
}


export const changePornstarPagination = (offset) => {
    return {
        type: actionTypes.CHANGE_PORNSTAR_PAGINATION,
        offset: offset,
    }
}

export const savePornstarsStart = () => {
    return {
        type: actionTypes.SAVE_PORNSTARS_START,
    }
}

export const filterPornstars = (payload) => {
    if (!payload) payload = {};

    return (dispatch, getState) => {


        payload.status = payload && payload.status ? payload.status : getState().pornstar.filters.status;
        payload.title = payload && payload.title ? payload.title : getState().pornstar.filters.title;
        payload.sort = payload && payload.sort ? payload.sort.value : getState().pornstar.filters.sort;

        if (payload.sort) {
            const postData = {
                domainID: getState().domain.activeDomain.id,
                ...payload
            }
            axios.post("pornstar/getPornstars", postData)
                .then(response => {
                    dispatch(saveFilteredPornstars(response.data, payload));
                })
        } else {

            let pornstars = getState().pornstar.pornstars;
            if (payload.status) {
                pornstars = pornstars.filter(pornstar => {
                    return pornstar.isActive === (payload.status.value === 1)
                });
            }

            if (payload.title) {
                pornstars = pornstars.filter(pornstar => {
                    return pornstar.title.toLowerCase().indexOf(payload.title.toLowerCase()) !== -1;
                });
            }


            dispatch(saveFilteredPornstars(pornstars, payload));
        }

    }
}

export const saveFilteredPornstars = (pornstars, filters) => {
    return {
        type: actionTypes.SAVE_FILTERED_PORNSTARS,
        pornstars: pornstars,
        filters: filters,
    }
}

export const resetAllPornstarsFilters = () => {
    return {
        type: actionTypes.RESET_ALL_PORNSTARS_FILTERS,
    }
}


export const changePornstarFiltersExpanded = (expanded) => {
    return {
        type: actionTypes.CHANGE_PORNSTAR_FILTERS_EXPANDED,
        expanded: expanded,
    }
}