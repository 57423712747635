import React from 'react';
import { Redirect } from 'react-router';

import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';


import Select from '../../components/Select/Select';


class Tags extends React.Component {

    state = {
        activeTag: null,
        redirect: null,
    }

    inputChangeHandler = (name, val) => {
        this.setState({
            [name]: val
        });
    }



    updateTag = (e) => {
        e.preventDefault();

        this.setState({
            redirect: "edit-tag/" + this.state.activeTag.value
        })
    }


    render() {
        if (this.state.redirect) {

            return <Redirect to={this.state.redirect} />
        }


        return (
            <div className="Tags" >
                <form onSubmit={this.updateTag}>
                    <div className="LinkArea">

                        <Select
                            changed={(val) => this.inputChangeHandler("activeTag", val)}
                            placeholder="Etiketler"
                            // options={this.state.tags}
                            label="Etiket"
                            ajaxUrl="tag/searchTag"
                            domainID={this.props.activeDomain.id}
                            className="tagSelect"
                        />
                        <Button type="submit" className="Button" variant="contained" color="primary" size="large" >
                            DÜZENLE
                    </Button>
                    </div>

                </form>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
    }
}


export default connect(mapStateToProps)(Tags);