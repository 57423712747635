import * as actionTypes from '../actions/actionTypes';

const initialState = {
    comments: [],
    filteredComments: [],
    loading: null,
    error: null,
    pagination: {
        limit: 20,
        offset: 0,
        total: 0,
    },
    filters: {
        title: "",
        status: null,
    },
    filtersExpanded: false,
}

const comments = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_COMMENTS:
            return {
                ...state,
                comments: action.comments,
                filteredComments: action.comments,
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.comments.length,
                },
            }
        case actionTypes.SAVE_COMMENTS_START:

            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.CHANGE_COMMENT_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    offset: action.offset,
                }
            }

        case actionTypes.SAVE_FILTERED_COMMENTS:

            return {
                ...state,
                filteredComments: action.comments,
                pagination: {
                    ...state.pagination,
                    total: action.comments.length,
                    offset: action.comments.length <= state.pagination.offset ? 0 : state.pagination.offset,
                },
                filters: action.filters,
            }
        case actionTypes.RESET_ALL_COMMENTS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    title: "",
                    status: null,
                }
            }
        case actionTypes.CHANGE_COMMENT_FILTERS_EXPANDED:
            return {
                ...state,
                filtersExpanded: action.expanded
            }
        default:
            return state;
    }
}

export default comments;