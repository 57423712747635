import * as actionTypes from '../actions/actionTypes';

const initialState = {
    settings: {},
    loading: null,
    error: null,
    activeTab: null,
}

const settings = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_SETTINGS:
            return {
                ...state,
                settings: action.settings,
                loading: false,
                error: null,
            }
        case actionTypes.SAVE_SETTINGS_START:

            return {
                ...state,
                loading: true,
                error: null,
            }

        case actionTypes.CHANGE_ACTIVE_SETTINGS_TAB:
            return {
                ...state,
                activeTab: action.activeTab
            }
        default:
            return state;
    }
}

export default settings;