import React from 'react';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';

import Select from '../../components/Select/Select';
import Pagination from "material-ui-flat-pagination";
import Pornstar from './Pornstar/Pornstar';

import Loading from '../../components/Loading/Loading';
import * as actions from '../../store/actions';
import { animateScroll } from 'react-scroll';

import { connect } from 'react-redux';

const statuses = [
    {
        label: "Aktif",
        value: 1,
    },
    {
        label: "Pasif",
        value: 0,
    },
]

const sortingList = [
    {
        label: "A-Z",
        value: "name",
    },
    {
        label: "A-Z",
        value: "nameDesc",
    },
    {
        label: "Video Sayısına Göre (Azalan)",
        value: "video_count",
    },
    {
        label: "Video Sayısına Göre (Artan)",
        value: "video_count_asc",
    },
    {
        label: "Trend Puanına Göre",
        value: "trending",
    },
    {
        label: "Abone Sayısına Göre",
        value: "subscriber_count",
    },
    {
        label: "En Beğenilenler - Bugün",
        value: "mostLikedD",
    },
    {
        label: "En Beğenilenler - Bu Hafta",
        value: "mostLikedW",
    },
    {
        label: "En Beğenilenler - Bu Ay",
        value: "mostLikedM",
    },
    {
        label: "En Beğenilenler - Tüm Zamanlar",
        value: "mostLikedA",
    },
    {
        label: "En Çok İzlenenler - Bugün",
        value: "mostViewedD",
    },
    {
        label: "En Çok İzlenenler - Bu Hafta",
        value: "mostViewedW",
    },
    {
        label: "En Çok İzlenenler - Bu Ay",
        value: "mostViewedM",
    },
    {
        label: "En Çok İzlenenler - Tüm Zamanlar",
        value: "mostViewedA",
    },

]

class Pornstars extends React.Component {

    state = {
        filters: {
            title: "",
            status: null,
            sort: null,
        },
    }

    filterChangeHandler = (name, val) => {
        let filters = { ...this.state.filters, [name]: val };
        this.setState({
            filters: filters
        });

    }

    resetAllFilters = () => {
        const filters = {
            ...this.state.filters
        }
        Object.keys(filters).forEach(f => {
            filters[f] = typeof filters[f] === "string" ? "" : null;
        });

        this.setState({
            filters: filters
        });

        this.props.onResetAllFilters();
    }

    paginationClickHandler = (offset) => {
        const paginationVal = { ...this.state.pagination, offset: offset }
        this.setState({
            pagination: paginationVal,
        });
    }

    filterPornstars = e => {
        if (e) {
            e.preventDefault();
        }


        const filters = {
            ...this.state.filters
        };
        this.props.onFilterPornstars(filters);
    }



    componentDidMount() {
        if (this.props.activeDomain.id) {
            this.props.onGetPornstars();
        }
        this.setState({
            filters: this.props.filters
        });

    }
    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && prevProps.activeDomain.id !== null && this.props.activeDomain !== prevProps.activeDomain) {
            this.props.onChangeDomain();
        }

        if (this.props.pornstars.length < 1 && this.props.activeDomain.id !== null && prevProps.activeDomain.id == null) {
            this.props.onGetPornstars();
        }

        return true;
    }

    render() {


        const pornstarsHTML = this.props.pornstars.map(data => {
            return <Pornstar {...data} key={data.id} />;
        });

        const loading = this.props.loading ? <Loading size={100} /> : null;

        return (
            <React.Fragment>
                {loading}
                <form onSubmit={this.filterPornstars} className="GeneralFilter">
                    <ExpansionPanel expanded={this.props.filtersExpanded} onChange={this.props.onChangeFiltersExpanded} className="GeneralFilter" CollapseProps={{
                        classes: {
                            container: "GeneralFilterContainer",
                        }
                    }} classes={{ expanded: "GeneralFilterExpanded" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <h4 className="Title">Filtreler</h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="ExpansionDetails" >
                            <Grid container spacing={24}>


                                <Grid item xs={12} sm={4} >
                                    <Input
                                        placeholder="Başlık"
                                        inputProps={{
                                            'aria-label': 'Description',
                                        }}
                                        className="GeneralInput"
                                        fullWidth
                                        onChange={(e) => { this.filterChangeHandler("title", e.target.value) }}
                                        value={this.state.filters.title}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <Select
                                        changed={(val) => this.filterChangeHandler("status", val)}
                                        placeholder="Durum Seç"
                                        options={statuses}
                                        value={this.state.filters.status}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <Select
                                        changed={(val) => this.filterChangeHandler("sort", val)}
                                        placeholder="Sıralama Seç"
                                        options={sortingList}
                                        value={this.state.filters.sort}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button size="small" onClick={this.resetAllFilters}>Sıfırla</Button>
                            <Button size="small" color="primary" type="submit">
                                Uygula
                        </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </form>
                <Grid container className="GeneralLists" spacing={24}>
                    {pornstarsHTML}
                </Grid>
                <Pagination
                    size="large"
                    limit={this.props.pagination.limit}
                    offset={this.props.pagination.offset}
                    total={this.props.pagination.total}
                    onClick={(e, offset) => this.props.onChangePagination(offset)}
                    classes={{
                        root: "paginationLists"
                    }}
                />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        pornstars: state.pornstar.filteredPornstars.slice(state.pornstar.pagination.offset, state.pornstar.pagination.offset + state.pornstar.pagination.limit),
        pagination: state.pornstar.pagination,
        activeDomain: state.domain.activeDomain,
        loading: state.pornstar.loading,
        filters: state.pornstar.filters,
        filtersExpanded: state.pornstar.filtersExpanded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePagination: (offset) => {
            animateScroll.scrollToTop({ containerId: "scrolledContent", duration: 500, })
            dispatch(actions.changePornstarPagination(offset));
        },
        onChangeDomain: () => dispatch(actions.savePornstars()),
        onGetPornstars: () => dispatch(actions.savePornstars()),
        onFilterPornstars: (payload) => dispatch(actions.filterPornstars(payload)),
        onResetAllFilters: () => dispatch(actions.resetAllPornstarsFilters()),
        onChangeFiltersExpanded: (e, expanded) => dispatch(actions.changePornstarFiltersExpanded(expanded)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pornstars);