import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const changeVideoPage = (offset) => {
    return (dispatch, getState) => {
        dispatch(getVideos({
            ...getState().video.filters,
            offset: offset,
        }));
    }
}

export const getVideos = (payload) => {


    return (dispatch, getState) => {
        dispatch(getVideosStart());

        if (!payload) payload = {};

        payload.offset = payload && (payload.offset || payload.offset === 0) ? payload.offset : getState().video.pagination.offset;
        payload.category = payload && (payload.category || payload.category === null) ? payload.category : getState().video.filters.category;
        payload.channel = payload && (payload.channel || payload.channel === null) ? payload.channel : getState().video.filters.channel;
        payload.pornstar = payload && (payload.pornstar || payload.pornstar === null) ? payload.pornstar : getState().video.filters.pornstar;
        payload.tag = payload && (payload.tag || payload.tag === null) ? payload.tag : getState().video.filters.tag;
        payload.status = payload && payload.status ? payload.status : getState().video.filters.status;
        payload.downloadStatus = payload && payload.downloadStatus ? payload.downloadStatus : getState().video.filters.downloadStatus;
        payload.title = payload && payload.title ? payload.title : getState().video.filters.title;
        payload.sort = payload && payload.sort ? payload.sort : getState().video.filters.sort;


        const domainID = getState().domain.activeDomain.id;
        const limit = getState().video.pagination.limit;
        const postData = {
            domainID: domainID,
            offset: payload.offset,
            limit: limit,
            category: payload.category,
            title: payload.title,
            channel: payload.channel,
            pornstar: payload.pornstar,
            tag: payload.tag,
            status: payload.status,
            downloadStatus: payload.downloadStatus,
            sort: payload.sort,
        }

        axios.post("video/getVideos", postData)
            .then(response => {
                dispatch(getVideosSuccess(response.data.videos, response.data.totalVideosCount, response.data.stats, payload));
            }).catch(err => {
            const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
            dispatch(getVideosError(msg));
        });
    }
}

export const refreshVideos = () => {
    return (dispatch, getState) => {
        const postData = {
            domainID: getState().domain.activeDomain.id,
            offset: getState().video.pagination.offset,
            limit: getState().video.pagination.limit,
            category: getState().video.filters.category,
            title: getState().video.filters.title,
            channel: getState().video.filters.channel,
            pornstar: getState().video.filters.pornstar,
            tag: getState().video.filters.tag,
            status: getState().video.filters.status,
            downloadStatus: getState().video.filters.downloadStatus,
            sort: getState().video.filters.sort,

        }

        axios.post("video/getVideos", postData)
            .then(response => {
                dispatch(refreshVideosSuccess(response.data.videos, response.data.totalVideosCount));
            }).catch(err => {
            const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
            dispatch(getVideosError(msg));
        });
    }
}

export const refreshVideosSuccess = (videos, count) => {
    return {
        type: actionTypes.REFRESH_VIDEOS_SUCCESS,
        videos: videos,
        count: count,
    }
}

export const getVideosStart = () => {
    return {
        type: actionTypes.SAVE_VIDEOS_START,
    }
}


export const getVideosSuccess = (videos, count, stats, filters) => {
    return {
        type: actionTypes.SAVE_VIDEOS_SUCCESS,
        videos: videos,
        count: count,
        stats: stats,
        filters: filters,
        offset: filters.offset,
    }
}

export const getVideosError = (err) => {
    return {
        type: actionTypes.SAVE_VIDEOS_ERROR,
        message: err,
    }
}

export const clearVideoError = (err) => {
    return {
        type: actionTypes.CLEAR_VIDEO_ERROR,
    }
}

export const changeFiltersExpanded = (expanded) => {
    return {
        type: actionTypes.CHANGE_FILTERS_EXPANDED,
        expanded: expanded,
    }
}

export const resetAllVideosFilters = () => {
    return {
        type: actionTypes.RESET_ALL_VIDEOS_FILTERS,
    }
}