import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './store/reducers/auth';
import domainReducer from './store/reducers/domain';
import categoryReducer from './store/reducers/category';
import pornstarReducer from './store/reducers/pornstar';
import channelReducer from './store/reducers/channel';
import videoReducer from './store/reducers/video';
import tagReducer from './store/reducers/tag';
import commentReducer from './store/reducers/comment';
import settingReducer from './store/reducers/setting';
import configReducer from './store/reducers/config';
import "./assets/css/material-dashboard-react.css?v=1.5.0";
import "./assets/css/custom.css";

import indexRoutes from "./routes/index.jsx";

const hist = createBrowserHistory();

const rootReducer = combineReducers({
  auth: authReducer,
  domain: domainReducer,
  category: categoryReducer,
  pornstar: pornstarReducer,
  channel: channelReducer,
  tag: tagReducer,
  video: videoReducer,
  comment: commentReducer,
  setting: settingReducer,
  config: configReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));



ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} component={prop.component} key={key} />;
        })}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);