import * as actionTypes from '../actions/actionTypes';

const initialState = {
    filters: {
        category: null,
        title: "",
        status: null,
        pornstar: null,
        channel: null,
        tag: null,
        sort: null,
    },
    pagination: {
        limit: 30,
        offset: 0,
        total: 0,
    },
    videos: [],
    loading: null,
    error: null,
    stats: {
        scheduled: 0,
        published: 0,
        draft: 0,
    },
    filtersExpanded: false,
}

const video = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_VIDEOS_START:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SAVE_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                videos: action.videos,
                filters: {
                    ...state.filters,
                    category: action.filters.category,
                    title: action.filters.title,
                    pornstar: action.filters.pornstar,
                    channel: action.filters.channel,
                    tag: action.filters.tag,
                    status: action.filters.status,
                    sort: action.filters.sort,
                },
                stats: action.stats,
                pagination: {
                    ...state.pagination,
                    total: action.count,
                    offset: action.offset,
                }
            }

        case actionTypes.REFRESH_VIDEOS_SUCCESS:
            return {
                ...state,
                videos: action.videos,
                pagination: {
                    ...state.pagination,
                    total: action.count,
                }
            }
        case actionTypes.SAVE_VIDEOS_ERROR:
            return {
                ...state,
                loading: null,
                error: action.message,
            }
        case actionTypes.CLEAR_VIDEO_ERROR:
            return {
                ...state,
                loading: null,
                error: null,
            }
        case actionTypes.CHANGE_FILTERS_EXPANDED:
            return {
                ...state,
                filtersExpanded: action.expanded
            }
        case actionTypes.RESET_ALL_VIDEOS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    category: null,
                    pornstar: null,
                    channel: null,
                    tag: null,
                    status: null,
                    sort: null,
                    title: "",
                }
            }

        default:
            return state;
    }
}

export default video;