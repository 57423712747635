import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {connect} from 'react-redux';
import Loading from '../../components/Loading/Loading';
import axios from '../../axios';

import * as actions from '../../store/actions';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {PhotoLibrary} from "@material-ui/icons";

class Tools extends React.Component {
    state = {
        loading: false,
        error: null,
        imageUrl: "",
        title: "",
        activeTab: 0,
        inputs: {
            url: "",
        }
    }

    inputChangeHandler = (name, val) => {
        let inputs = {...this.state.inputs, [name]: val};

        this.setState({
            inputs: inputs
        });
    }

    componentDidMount() {
    }


    submitForm = () => {
        this.setState({
            loading: true,
            imageUrl: "",
            title: "",
        });

        const postData = this.state.inputs;

        axios.post("tools/fetch-premium-site-photo", postData)
            .then(response => {
                this.setState({
                    imageUrl: response.data.imageUrl,
                    title: response.data.title,
                    loading: false,
                });
            })
            .catch(err => {
                console.log(err);
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }


    errorCloseHandler = () => {
        this.setState({
            error: null,
        });
    }

    render() {
        let loading = null;
        let tabContent = null;

        if (this.state.loading) {
            loading = <Loading size={100}/>;
        }

        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        console.log(this.state.activeTab);
        switch (this.state.activeTab) {

            case 0: // General

                let videoInfo = null;
                if (this.state.imageUrl && this.state.title) {
                    videoInfo = <Grid item xs={12} sm={12} xl={12} className={"Ece"}>

                        <Divider style={{marginTop: "30px", marginBottom: "30px"}}/>
                        <h2>{this.state.title}</h2>
                        <img
                            src={this.state.imageUrl}
                            alt=""/>
                    </Grid>;
                }
                tabContent = (
                    <React.Fragment>
                        <Paper square className="GeneralPaper ">
                            <Grid container spacing={24}>

                                <Grid item xs={12} sm={12} xl={8}>


                                    <TextField
                                        type={"url"}
                                        fullWidth
                                        label="Video Linki"
                                        value={this.state.inputs.videoUrl}
                                        margin="normal"
                                        helperText="Brazzers, DigitalPlayground, Nubiles, Anilos, NubileFilms, NubilesPorn, FamilyStrokes, Naturals21, Nympho, DadCrush, PassionHD, RealityKings videolarında çalışır."
                                        onChange={(e) => this.inputChangeHandler("videoUrl", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} xl={4}>
                                    <Button style={{display: "block", width: "100%", marginTop: "30px"}}
                                            onClick={this.submitForm} variant="contained" color="primary">
                                        BUL
                                    </Button>
                                </Grid>
                            </Grid>


                            {videoInfo}

                        </Paper>
                    </React.Fragment>
                );
                break;


            default:
                tabContent = null;
                break;
        }


        return (
            <Grid container spacing={24}>

                {loading}
                {error}
                <Grid item xs={12} sm={12} lg={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.activeTab}
                            onChange={this.changeTabHandle}
                            variant="fullWidth"
                            scrollButtons="auto"
                            indicatorColor="secondary"
                        >

                            <Tab icon={<PhotoLibrary/>} label="Premium Site Fotoğraf Çekme Aracı"/>
                        </Tabs>
                    </AppBar>

                    {tabContent}
                </Grid>
            </Grid>
        );
    }
}


const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
        activeUser: state.auth.user
    }
}

const mapStateToDispatch = dispatch => {
    return {
        onChangeDomain: () => dispatch(actions.saveSettings()),
    }
}

export default connect(mapStateToProps, mapStateToDispatch)(Tools);