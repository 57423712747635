import * as actionTypes from '../actions/actionTypes';

const initialState = {
    categories: [],
    filteredCategories: [],
    loading: null,
    error: null,
    pagination: {
        limit: 48,
        offset: 0,
        total: 0,
    },
    filters: {
        title: "",
        status: null,
        sort: null,
    },
    filtersExpanded: false,
}

const categories = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
                filteredCategories: action.categories,
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.categories.length,
                },
            }
        case actionTypes.SAVE_CATEGORIES_START:

            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.CHANGE_CATEGORY_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    offset: action.offset,
                }
            }

        case actionTypes.SAVE_FILTERED_CATEGORIES:

            return {
                ...state,
                filteredCategories: action.categories,
                pagination: {
                    ...state.pagination,
                    total: action.categories.length,
                    offset: 0,
                },
                filters: action.filters,
            }
        case actionTypes.RESET_ALL_CATEGORIES_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    title: "",
                    status: null,
                    sort: null,
                }
            }
        case actionTypes.CHANGE_CATEGORY_FILTERS_EXPANDED:
            return {
                ...state,
                filtersExpanded: action.expanded
            }
        default:
            return state;
    }
}

export default categories;