import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const saveSettings = () => {
    return (dispatch, getState) => {
        dispatch(saveSettingsStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
        }
        axios.post("setting/getSettings", postData)
            .then(response => {
                dispatch(saveSettingsToState(response.data));
            })
    }
}

export const saveSettingsToState = (settings) => {
    return {
        type: actionTypes.SAVE_SETTINGS,
        settings: settings,
    }
}

export const saveSettingsStart = () => {
    return {
        type: actionTypes.SAVE_SETTINGS_START,
    }
}