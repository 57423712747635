import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: null,
    error: null,
    refresh_token: null,
    auth_token_failed: false,
    user: {
        name: null,
        id: null,
        email: null,
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                error: null
            }
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                error: action.error
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                token: action.token,
                refresh_token: action.refresh_token,
                error: null,
                user: {
                    ...state.user,
                    name: action.name,
                    id: action.userID,
                    email: action.email
                }
            }
        case actionTypes.AUTH_WITH_TOKEN_FAIL:
            return {
                ...state,
                auth_token_failed: true,
            }
        case actionTypes.LOGOUT:
            return {
                ...state,
                token: null,
                refresh_token: null,
                user: {
                    ...state.user,
                    name: null,
                    id: null,
                    email: null
                }
            }
        default:
            return state;
    }
}

export default reducer;