import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import PlayIcon from "@material-ui/icons/PlayArrow";
import Visibility from "@material-ui/icons/Visibility";
import CloudUpload from "@material-ui/icons/CloudUpload";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '../../../components/Select/Select';
import SpeakingUrl from 'speakingurl';
import Moment from 'moment';
import Badge from '@material-ui/core/Badge';
import SelectPhoto from '../../../components/SelectPhoto/SelectPhoto';
import JwPlayer from '../../../components/JwPlayer/JwPlayer';
import Loading from '../../../components/Loading/Loading';
import Snackbar from '@material-ui/core/Snackbar';

import * as actions from '../../../store/actions';

import {Redirect} from 'react-router';

import {connect} from 'react-redux';

import axios from '../../../axios';

class AddVideo extends React.Component {
    state = {
        imageOpen: false,
        videoOpen: false,
        deleteDialog: false,
        photoSelect: false,
        imageOpenUrl: null,
        showForm: false,
        categories: [],
        pornstars: [],
        channels: [],
        loading: false,
        error: null,
        success: null,
        dmcaWarning: null,
        redirect: null,
        redirectSettingAfterAddVideo: "videos",
        video: {
            sourceUrl: "",
            title: "",
            slug: "",
            pornstars: [],
            categories: [],
            tags: [],
            channels: [],
            description: "",
            seoTitle: "",
            seoDescription: "",
            date: Moment().local().format("YYYY-MM-DDTHH:mm"),
            differentSlug: false,
            site: {},
            cover: "",
            photos: [],
            seconds: 0,
            duration: "",
            videoSources: {},
            miniVideoUrl: "",
            customPhotos: [],
            willPublishReddit: true,
            willPublishTwitter: true,
            willPublishVk: true,
            willPublishTelegram: true,
            autoTag: null,
            isDmca: false
        },
        seo: {
            titleLimit: 60,
            descriptionLimit: 230,
        }
    }

    redirectSettingsHandle = e => {
        localStorage.setItem("redirectSettingAfterAddVideo", e.target.value);
        this.setState({
            redirectSettingAfterAddVideo: e.target.value,
        });
    }


    addVideoRequest = options => {
        this.setState({
            loading: true,
        });

        const requiredInfos = [
            this.state.video.sourceUrl,
            this.state.video.title,
            this.state.video.slug,
            this.state.video.cover,
            this.state.video.seconds,
            this.state.video.categories,
        ];

        let completeRequireInfo = true;

        requiredInfos.forEach(i => {
            let complete = false;
            switch (typeof i) {
                case "string":
                    complete = i !== "";
                    break;
                case "object":
                    complete = i.length > 0;
                    break;
                case "array":
                    complete = i.length > 0;
                    break;
                case "number":
                    complete = i > 0;
                    break;
                default:
                    complete = false;
                    break;
            }

            if (completeRequireInfo !== false) {
                completeRequireInfo = complete;
            }
        });

        if (completeRequireInfo !== true) {
            this.setState({
                error: "Tüm zorunlu alanları doldurun.",
                loading: false,
            })

            return false;
        }

        const postData = this.state.video;
        postData.domainID = this.props.activeDomainID;

        if (options && options.draft) {
            postData.draft = true;
        }

        axios.post("video/post", postData)
            .then(response => {

                if (this.state.redirectSettingAfterAddVideo === "addVideo") {
                    this.setState({
                        loading: false,
                        showForm: false,
                        success: "Video başarıyla eklendi",
                        video: {
                            ...this.state.video,
                            sourceUrl: "",
                        }
                    });
                } else {
                    this.setState({
                        loading: false,
                        redirect: "/videos"
                    });
                }


            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }


    open = (name, value) => {
        if (name === "imageOpen") {
            this.setState({[name]: true, imageOpenUrl: value});
        } else {
            this.setState({[name]: true});
        }

    }

    close = (name) => {
        this.setState({[name]: false});
    }

    inputChangeHandler = (name, val) => {
        let video = {...this.state.video, [name]: val};
        this.setState({
            video: video
        });
    }

    checkboxChangeHandler = name => event => {
        let video = {...this.state.video, [name]: event.target.checked};
        this.setState({
            video: video
        });
    }

    seoChangeHandler = (name, val) => {
        let seo = {...this.state.seo};
        let inputName = "";
        if (name === "title") {
            if (seo.titleLimit < val.length) {
                // return false;
            }
            inputName = "seoTitle";
        } else if (name === "description") {
            if (seo.descriptionLimit < val.length) {
                // return false;
            }
            inputName = "seoDescription";
        }

        this.setState({
            seo: seo
        });
        this.inputChangeHandler(inputName, val);

    }

    titleChangeHandler = (name, value) => {
        this.inputChangeHandler("title", value);
        if (!this.state.video.differentSlug) {
            let slug = SpeakingUrl(value, {
                custom: {
                    'ö': 'o',
                    'ü': 'u',
                }
            });
            if (!slug) {
                slug = this.randomStr(16);
            }
            setTimeout(() => {
                this.inputChangeHandler("slug", slug);
            }, 5);
        }

    }

    randomStr = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    slugChangeHandler = (name, value) => {
        this.inputChangeHandler(name, value);
        setTimeout(() => {
            this.inputChangeHandler("differentSlug", true);
        }, 5);
    }

    selectMapping = (arr) => {
        return arr.map(c => {
            let returnedData = {
                label: c.name,
                value: c.id,
            }
            if (c.__isNew__) {
                returnedData.__isNew__ = true;
            }
            return returnedData;
        });
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.activeDomainID !== nextProps.activeDomainID) {
            this.setState({
                showForm: false,
            });
        }

        return true;
    }

    uploadInputChangeHandler = e => {

        const formData = new FormData();
        formData.append("photo", e.target.files[0]);
        this.setState({
            loading: true,
        })

        axios.post("/video/upload-photo", formData)
            .then(response => {
                let video = {...this.state.video};
                video.photos.push(response.data.url);
                video.customPhotos.push(response.data.url);
                this.setState({
                    video: video,
                    loading: false,
                });
            })

    }

    componentDidMount() {
        if (localStorage.redirectSettingAfterAddVideo) {
            this.setState({
                redirectSettingAfterAddVideo: localStorage.redirectSettingAfterAddVideo,
            });
        }

    }


    findVideo = (e) => {
        e.preventDefault();

        axios.defaults.headers.common['Authorization'] = this.props.token;
        const postData = {
            url: this.state.video.sourceUrl,
            domainID: this.props.activeDomainID
        }
        this.setState({
            loading: true,
        });

        axios.post("/video/find", postData)
            .then(response => {
                const selectedCategories = this.selectMapping(response.data.result.categories);
                const selectedPornstars = this.selectMapping(response.data.result.pornstars);
                const selectedChannels = this.selectMapping(response.data.result.channels);
                const selectedTags = this.selectMapping(response.data.result.tags);

                const videoSources = Object.keys(response.data.result.sources).map(s => {
                    return {
                        label: s,
                        file: response.data.result.sources[s],
                        type: "video/mp4",
                    }
                })

                let slug = SpeakingUrl(response.data.result.title, {
                    custom: {
                        'ö': 'o',
                        'ü': 'u',
                    }
                });

                if (!slug) {
                    slug = this.randomStr(16);
                }


                this.setState({
                    showForm: true,
                    loading: false,
                    video: {
                        ...this.state.video,
                        title: response.data.result.title,
                        slug: slug,
                        description: response.data.result.description,
                        cover: response.data.result.cover,
                        photos: response.data.result.photos,
                        categories: selectedCategories,
                        pornstars: selectedPornstars,
                        channels: selectedChannels,
                        tags: selectedTags,
                        seconds: response.data.result.duration,
                        duration: Moment.utc(response.data.result.duration * 1000).format('mm:ss'),
                        sourceSite: response.data.result.sourceSite,
                        videoSources: videoSources,
                        miniVideoUrl: response.data.result.miniVideoUrl,
                        autoTag: response.data.result.autoTag,
                        differentSlug: false,
                    },
                    isDmcaWarning: response.data.isDmca ? true : null,
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            })

    }

    errorCloseHandler = () => {
        this.setState({
            error: null,
        });
    }

    successCloseHandler = () => {
        this.setState({
            success: null,
        });
    }


    dmcaWarningCloseHandler = () => {
        this.setState({
            isDmcaWarning: null,
        });
    }


    render() {
        const photos = this.state.video.photos.map(photo => {
            return <SelectPhoto key={photo} img={photo} checked={photo === this.state.video.cover} checkClick={() => {
                this.inputChangeHandler("cover", photo)
            }} showClick={() => {
                this.open("imageOpen", photo)
            }}/>;
        })


        const loading = this.state.loading ? <Loading size={100}/> : null;

        let addForm = null;

        let error = null;
        let success = null;

        const redirect = this.state.redirect ? <Redirect to={{
            pathname: this.state.redirect,
            state: {
                updateVideos: true,
            }
        }}/> : null;

        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }


        if (this.state.success) {
            success = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.success !== null}
                    autoHideDuration={10000}
                    onClose={this.successCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar success"
                    }}

                    message={<span id="message-id">{this.state.success}</span>}
                />
            );
        }

        let dmcaContentWarning = null;
        if (this.state.isDmcaWarning) {
            dmcaContentWarning = <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.state.isDmcaWarning !== null}
                onClose={this.dmcaWarningCloseHandler}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    'className': "snackbar warning"
                }}

                message={<span
                    id="message-id">Bu video sistemdeki başka bir sitede telifli olarak işaretlenmiş durumda.</span>}
            />;
        }


        let redditMediaSharings;
        if (this.props.settings.isRedditActive) {
            redditMediaSharings = (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.video.willPublishReddit}
                            onChange={this.checkboxChangeHandler('willPublishReddit')}
                            value="1"
                            color="primary"
                        />
                    }
                    label="Reddit'te Paylaşılsın"
                    style={{
                        width: "100%"
                    }}
                />
            );
        } else {
            redditMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="Reddit Hesabı Aktif Değil"
                    style={{
                        width: "100%",
                    }}
                />
            )
        }

        let twitterMediaSharings;
        if (this.props.settings.isTwitterActive) {
            twitterMediaSharings = (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.video.willPublishTwitter}
                            onChange={this.checkboxChangeHandler('willPublishTwitter')}
                            value="1"
                            color="primary"
                        />
                    }
                    label="Twitter'da Paylaşılsın"
                    style={{
                        width: "100%"
                    }}
                />
            )
        } else {
            twitterMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="Twitter Hesabı Aktif Değil"
                    style={{
                        width: "100%",
                    }}
                />
            )
        }

        let vkMediaSharings;
        if (this.props.settings.isVkActive) {
            vkMediaSharings = (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.video.willPublishVk}
                            onChange={this.checkboxChangeHandler('willPublishVk')}
                            value="1"
                            color="primary"
                        />
                    }
                    label="VK'da Paylaşılsın"
                    style={{
                        width: "100%"
                    }}
                />
            )
        } else {

            vkMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="VK Hesabı Aktif Değil"
                    style={{
                        width: "100%",
                    }}
                />
            )
        }

        let telegramMediaSharings;
        if (this.props.settings.isTelegramActive) {
            telegramMediaSharings = (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.video.willPublishTelegram}
                            onChange={this.checkboxChangeHandler('willPublishTelegram')}
                            value="1"
                            color="primary"
                        />
                    }
                    label="Telegram'da Paylaşılsın"
                    style={{
                        width: "100%"
                    }}
                />
            );
        } else {
            telegramMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="Telegram Hesabı Aktif Değil"
                    style={{
                        width: "100%",
                    }}
                />
            )
        }

        let autoTagRow = null;
        if (this.state.video.autoTag) {
            autoTagRow = <TableRow>
                <TableCell className="th">Belirlenen Etiket:</TableCell>
                <TableCell>{this.state.video.autoTag}</TableCell>
            </TableRow>
        }


        if (this.state.showForm) {
            addForm = (
                <div className="VideoDetails">

                    <Dialog
                        open={this.state.imageOpen}
                        onClose={() => {
                            this.close("imageOpen")
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="lg"
                    >
                        <DialogContent>
                            <img src={this.state.imageOpenUrl} style={{maxWidth: "100%"}} alt=""/>
                        </DialogContent>

                    </Dialog>
                    <Dialog
                        open={this.state.videoOpen}
                        onClose={() => this.close("videoOpen")}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="lg"
                        fullWidth>
                        <DialogContent>
                            <JwPlayer
                                id="ufuk"
                                image={this.state.video.cover}
                                sources={this.state.video.videoSources}
                            />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.photoSelect}
                        onClose={() => {
                            this.close("photoSelect")
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth={false}>
                        <DialogContent>
                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={9} xl={10}>
                                    <Grid container spacing={24}>
                                        {photos}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={3} xl={2} className="PhotoUploadButton">
                                    <input id="photoUploadInput" accept="image/*"
                                           onChange={this.uploadInputChangeHandler} type="file"
                                           style={{position: "absolute", top: "-9999px"}}/>
                                    <label htmlFor="photoUploadInput">
                                        <Button variant="contained" component="span" color="default">
                                            FARKLI FOTOĞRAF YÜKLE&nbsp;&nbsp;
                                            <CloudUpload/>
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={3} xl={3}>
                            <Paper className="LeftSide" elevation={1} square>
                                <div className="Cover">
                                    <img src={this.state.video.cover} alt={this.state.video.title}/>
                                    <div className="Buttons">
                                        <PlayIcon onClick={() => this.open("videoOpen")}/>
                                        <Visibility onClick={() => this.open("imageOpen", this.state.video.cover)}/>
                                        <Badge onClick={() => {
                                            this.open("photoSelect")
                                        }} className="PhotoLibraryBadge" badgeContent={this.state.video.photos.length}
                                               color="primary" style={{top: "-1px"}}>
                                            <PhotoLibrary/>
                                        </Badge>
                                    </div>
                                    <div className="DarkLayer"></div>
                                </div>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="th">Süre:</TableCell>
                                            <TableCell>{this.state.video.duration}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="th">Kaynak Site:</TableCell>
                                            <TableCell>{this.state.video.sourceSite}</TableCell>
                                        </TableRow>
                                        {autoTagRow}
                                    </TableBody>
                                </Table>

                            </Paper>
                            <Paper className="LeftSide GeneralPaper RadioPaper" elevation={1} square>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Video Eklendikten Sonra Ne Yapılsın?</FormLabel>
                                    <RadioGroup
                                        aria-label="Gender"
                                        name="gender1"
                                        value={this.state.redirectSettingAfterAddVideo}
                                        onChange={this.redirectSettingsHandle}
                                    >
                                        <FormControlLabel value="videos" control={<Radio/>}
                                                          label="Videolar Sayfasına Dönülsün"/>
                                        <FormControlLabel value="addVideo" control={<Radio/>}
                                                          label="Video Ekle Sayfasında Kalınsın"/>

                                    </RadioGroup>
                                </FormControl>
                            </Paper>


                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                            <Paper className="GeneralPaper" elevation={1} square>
                                <TextField
                                    fullWidth
                                    label="Başlık"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => this.titleChangeHandler("title", e.target.value)}
                                    value={this.state.video.title}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Link Yapısı"
                                    value={this.state.video.slug}
                                    onChange={(e) => this.slugChangeHandler("slug", e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Paper>
                            <Paper className="GeneralPaper" elevation={1} square>
                                <Select
                                    changed={(val) => this.inputChangeHandler("pornstars", val)}
                                    placeholder="Yıldızlar"
                                    // options={this.state.pornstars}
                                    value={this.state.video.pornstars}
                                    label="Yıldızlar"
                                    multi
                                    ajaxUrl="pornstar/searchPornstar"
                                    domainID={this.props.activeDomainID}
                                    className="AddVideoSelect"
                                />
                                <Select
                                    changed={(val) => this.inputChangeHandler("categories", val)}
                                    placeholder="Kategoriler"
                                    // options={this.state.categories}
                                    value={this.state.video.categories}
                                    label="Kategoriler"
                                    multi
                                    ajaxUrl="category/searchCategory"
                                    domainID={this.props.activeDomainID}
                                    className="AddVideoSelect"
                                />
                                <Select
                                    changed={(val) => this.inputChangeHandler("tags", val)}
                                    placeholder="Etiketler"
                                    // options={this.state.tags}
                                    value={this.state.video.tags}
                                    label="Etiketler"
                                    multi
                                    creatable
                                    ajaxUrl="tag/searchTag"
                                    domainID={this.props.activeDomainID}
                                    className="AddVideoSelect"
                                />
                                <Select
                                    changed={(val) => this.inputChangeHandler("channels", val)}
                                    placeholder="Kanallar"
                                    options={this.state.channels}
                                    value={this.state.video.channels}
                                    label="Kanallar"
                                    ajaxUrl="channel/searchChannel"
                                    domainID={this.props.activeDomainID}
                                    multi
                                    className="AddVideoSelect"
                                />
                            </Paper>
                            <Paper className="GeneralPaper" elevation={1} square>
                                <TextField
                                    fullWidth
                                    label="Açıklama"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={5}
                                    onChange={(e) => this.inputChangeHandler("description", e.target.value)}
                                    value={this.state.video.description}
                                    rowsMax={10}
                                />
                            </Paper>
                            <Paper className="GeneralPaper" elevation={1} square>
                                <TextField
                                    fullWidth
                                    label="Seo Başlığı"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.video.seoTitle}
                                    onChange={(e) => this.seoChangeHandler("title", e.target.value)}
                                    helperText={this.state.video.seoTitle.length + " / " + this.state.seo.titleLimit}
                                />
                                <TextField
                                    fullWidth
                                    label="Seo Açıklaması"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={3}
                                    value={this.state.video.seoDescription}
                                    margin="normal"
                                    onChange={(e) => this.seoChangeHandler("description", e.target.value)}
                                    helperText={this.state.video.seoDescription.length + " / " + this.state.seo.descriptionLimit}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>

                            <Paper className="RightSide  GeneralPaper" elevation={1} square>
                                <Typography variant="h5">Sosyal Medya Paylaşımı</Typography>
                                {redditMediaSharings}
                                {twitterMediaSharings}
                                {vkMediaSharings}
                                {telegramMediaSharings}

                            </Paper>
                            <Paper className="RightSide  GeneralPaper" elevation={1} square>

                                <TextField
                                    label="Yayınlanma Tarihi"
                                    type="datetime-local"
                                    fullWidth
                                    onChange={(e) => this.inputChangeHandler("date", e.target.value)}
                                    value={this.state.video.date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Button onClick={() => this.addVideoRequest({draft: true})}
                                        style={{display: "block", width: "100%", marginTop: "30px"}} variant="outlined"
                                        color="default">
                                    TASLAK OLARAK KAYDET
                                </Button>

                                <Button onClick={this.addVideoRequest}
                                        style={{display: "block", width: "100%", marginTop: "15px"}} variant="contained"
                                        color="primary">
                                    YAYINLA
                                </Button>

                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} xl={12}>
                            <Grid container spacing={24}>
                                {photos}
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            );
        }


        const findHelperText = (
            <span className="AvailableSources">
                <strong>Eklenebilecek Siteler: </strong> <a href="http://pornhub.com" rel="noopener noreferrer"
                                                            target="_blank">PornHub</a>, <a href="http://youporn.com"
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank">YouPorn</a>, <a
                href="http://redtube.com" rel="noopener noreferrer" target="_blank">RedTube</a>, <a
                href="http://spankbang.com" rel="noopener noreferrer" target="_blank">SpankBang</a>, <a
                href="http://eporner.com" rel="noopener noreferrer" target="_blank">ePorner</a>, <a
                href="http://beeg.com" rel="noopener noreferrer" target="_blank">Beeg</a>, <a href="http://porntrex.com"
                                                                                              rel="noopener noreferrer"
                                                                                              target="_blank">PornTreX</a>, <a
                href="http://xhamster.com" rel="noopener noreferrer" target="_blank">xHamster</a>, <a
                href="http://3movs.com" rel="noopener noreferrer" target="_blank">3Movs</a>
            </span>
        );
        return (

            <div className="AddVideo">
                {loading}
                {error}
                {success}
                {dmcaContentWarning}
                {redirect}
                <form onSubmit={this.findVideo}>
                    <div className="LinkArea">
                        <TextField
                            className="Input"
                            label="Video Linki"
                            value={this.state.video.sourceUrl}
                            onChange={(e) => this.inputChangeHandler('sourceUrl', e.target.value)}
                            margin="normal"
                            variant="outlined"
                            type="url"
                            inputProps={{
                                required: "required",
                                type: "url"
                            }}
                            helperText={findHelperText}
                        />
                        <Button type="submit" className="Button" variant="contained" color="primary" size="large">
                            BUL
                        </Button>
                    </div>

                </form>
                {addForm}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        activeDomainID: state.domain.activeDomain.id,
        sources: state.config.configs.sources,
        settings: state.setting.settings,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateVideos: () => dispatch(actions.getVideos()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVideo);