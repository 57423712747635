import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Select from "../../../components/Select/Select";
import SpeakingUrl from "speakingurl";
import Loading from "../../../components/Loading/Loading";
import Snackbar from "@material-ui/core/Snackbar";

import { Redirect } from "react-router";

import { connect } from "react-redux";

import axios from "../../../axios";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

class AddContent extends React.Component {
    state = {

        deleteDialog: false,

        categories: [],
        loading: false,
        error: null,
        success: null,
        editor: EditorState.createEmpty(),
        content: {
            title: "",
            slug: "",
            content: "",
            categories: [],
            differentSlug: false
        }
    };

    componentDidMount() {
        if (this.props.activeDomainID) {
            this.getCategories();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeDomainID !== prevProps.activeDomainID) {
            this.getCategories();
        }
    }

    getCategories = () => {
        this.setState({
            loading: true
        });

        axios.post("blog/category/get", {
            domainID: this.props.activeDomainID
        })
            .then(response => {
                this.setState({
                    loading: false,
                    categories: response.data
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: "Bir hata oluştu."
                });
            });
    };

    addContentRequest = options => {

        this.setState({
            loading: true
        });

        const requiredInfos = [
            this.state.content.title,
            this.state.content.slug,
            this.state.content.content,
            this.state.content.categories
        ];

        let completeRequireInfo = true;

        requiredInfos.forEach(i => {
            let complete = false;
            switch (typeof i) {
                case "string":
                    complete = i !== "";
                    break;
                case "object":
                    complete = i.length > 0;
                    break;
                case "array":
                    complete = i.length > 0;
                    break;
                case "number":
                    complete = i > 0;
                    break;
                default:
                    complete = false;
                    break;
            }

            if (completeRequireInfo !== false) {
                completeRequireInfo = complete;
            }
        });

        if (completeRequireInfo !== true) {
            this.setState({
                error: "Tüm zorunlu alanları doldurun.",
                loading: false
            });

            return false;
        }

        const postData = this.state.content;

        postData.categories = postData.categories.map(c => {
            return c.value;
        });
        postData.domainID = this.props.activeDomainID;

        if (options && options.draft) {
            postData.is_active = 0;
        } else {
            postData.is_active = 1;
        }

        axios.post("blog/add", postData)
            .then(response => {
                this.setState({
                    loading: false,
                    redirect: "/blog"
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    editorChangeHandler = (editorState) => {
        this.setState({
            editor: editorState,
            content: {
                ...this.state.content,
                content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            }
        });
    };

    inputChangeHandler = (name, val) => {
        let content = { ...this.state.content, [name]: val };
        this.setState({
            content: content
        });
    };

    checkboxChangeHandler = name => event => {
        let content = { ...this.state.content, [name]: event.target.checked };
        this.setState({
            content: content
        });
    };

    titleChangeHandler = (name, value) => {
        this.inputChangeHandler("title", value);
        if (!this.state.content.differentSlug) {
            let slug = SpeakingUrl(value, {
                custom: {
                    "ö": "o",
                    "ü": "u"
                }
            });
            if (!slug) {
                slug = this.randomStr(16);
            }
            setTimeout(() => {
                this.inputChangeHandler("slug", slug);
            }, 5);
        }

    };

    randomStr = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    };

    slugChangeHandler = (name, value) => {
        this.inputChangeHandler(name, value);
        setTimeout(() => {
            this.inputChangeHandler("differentSlug", true);
        }, 5);
    };

    selectMapping = (arr) => {
        return arr.map(c => {
            let returnedData = {
                label: c.name,
                value: c.id
            };
            if (c.__isNew__) {
                returnedData.__isNew__ = true;
            }
            return returnedData;
        });
    };

    errorCloseHandler = () => {
        this.setState({
            error: null
        });
    };

    successCloseHandler = () => {
        this.setState({
            success: null
        });
    };

    imageUploadHandler = (file) => {
        return new Promise(
            (resolve, reject) => {
                const formData = new FormData();
                formData.append("image", file);
                formData.append("domainID", this.props.activeDomainID);
                axios.post("blog/upload-image", formData)
                    .then(response => {
                        resolve({ data: { link: response.data.image } });
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        );
    };

    render() {
        const loading = this.state.loading ? <Loading size={100} /> : null;

        let error = null;
        let success = null;

        const redirect = this.state.redirect ? <Redirect to={{
            pathname: this.state.redirect
        }} /> : null;

        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        "className": "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }


        if (this.state.success) {
            success = (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={this.state.success !== null}
                    autoHideDuration={10000}
                    onClose={this.successCloseHandler}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        "className": "snackbar success"
                    }}

                    message={<span id="message-id">{this.state.success}</span>}
                />
            );
        }

        const options = this.selectMapping(this.state.categories);

        return (

            <div className="AddVideo">
                {loading}
                {error}
                {success}
                {redirect}
                <div className="VideoDetails">

                    <Grid container spacing={24}>

                        <Grid item xs={12} sm={9} xl={9}>
                            <Paper className="GeneralPaper" elevation={1} square>
                                <TextField
                                    fullWidth
                                    label="Başlık"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => this.titleChangeHandler("title", e.target.value)}
                                    value={this.state.content.title}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Link Yapısı"
                                    value={this.state.content.slug}
                                    onChange={(e) => this.slugChangeHandler("slug", e.target.value)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Paper>

                            <Paper className="GeneralPaper" elevation={1} square>
                                <Editor
                                    editorState={this.state.editor}
                                    editorStyle={{
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                        minHeight: "500px",
                                        padding: "10px",
                                        resize: "vertical"
                                    }}
                                    toolbar={{
                                        image: {
                                            uploadCallback: this.imageUploadHandler,
                                            alt: { present: true }
                                        }
                                    }}
                                    onEditorStateChange={this.editorChangeHandler}
                                />
                            </Paper>


                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <Paper className="GeneralPaper" elevation={1} square>
                                <Select
                                    changed={(val) => this.inputChangeHandler("categories", val)}
                                    placeholder="Kategoriler"
                                    options={options}
                                    value={this.state.content.categories}
                                    label="Kategoriler"
                                    multi
                                    domainID={this.props.activeDomainID}
                                    className="AddVideoSelect"
                                />
                            </Paper>

                            <Paper className="RightSide  GeneralPaper" elevation={1} square>
                                <Button onClick={() => this.addContentRequest({ draft: true })}
                                        style={{ display: "block", width: "100%", marginTop: "30px" }}
                                        variant="outlined"
                                        color="default">
                                    TASLAK OLARAK KAYDET
                                </Button>

                                <Button onClick={this.addContentRequest}
                                        style={{ display: "block", width: "100%", marginTop: "15px" }}
                                        variant="contained"
                                        color="primary">
                                    YAYINLA
                                </Button>

                            </Paper>
                        </Grid>
                    </Grid>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        activeDomainID: state.domain.activeDomain.id
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContent);