import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

class Loading extends React.Component {
    state = {
        completed: 0,
    };

    componentDidMount() {
        this.timer = setInterval(this.progress, 20);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    progress = () => {
        const { completed } = this.state;
        this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
    };

    render() {
        return (
            <div className="Loading">
                <CircularProgress
                    variant="determinate"
                    size={this.props.size ? this.props.size : 50}
                    value={this.state.completed}
                />
            </div>
        );
    }
}

export default Loading;