import React from "react";
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Hidden from "@material-ui/core/Hidden";
// import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
// import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Select from '../Select/Select';
import * as actions from '../../store/actions';

import { connect } from 'react-redux';

class HeaderLinks extends React.Component {
  // state = {
  //   open: false
  // };
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  changeDomain = (val) => {

    let selectedDomain = this.props.domains.filter(d => {
      return d.id === val.value;
    })[0];

    this.props.onChangeDomain(val.value, selectedDomain.domain, val.label);
  }


  render() {

    const sites = this.props.domains.map(d => {
      return {
        label: d.site_name,
        value: d.id,
      };
    });

    const activeDomain = {
      label: this.props.activeDomain.title,
      value: this.props.activeDomain.id,
    }

    return (
      <div>
        <Select
          changed={this.changeDomain}
          placeholder="Site Ara"
          options={sites}
          value={activeDomain}
          notClearable
          minWidth="50px"
        />

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    domains: state.domain.domains,
    activeDomain: state.domain.activeDomain,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeDomain: (id, domain, title) => dispatch(actions.changeDefaultDomain(id, domain, title))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(headerLinksStyle)(HeaderLinks));
