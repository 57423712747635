import React from 'react';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Select from '../../components/Select/Select';
import Pagination from "material-ui-flat-pagination";
import Comment from './Comment/Comment';

import Loading from '../../components/Loading/Loading';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { animateScroll } from 'react-scroll';


const statuses = [
    {
        label: "Onaylılar",
        value: 1,
    },
    {
        label: "Onaysızlar",
        value: 0,
    },
]

class Comments extends React.Component {

    state = {
        filters: {
            site: null,
            title: "",
            status: null,
        },
        deleteDialog: false,
        deleteID: null,
    }

    filterChangeHandler = (name, val) => {
        let filters = { ...this.state.filters, [name]: val };
        this.setState({
            filters: filters
        });

    }


    resetAllFilters = () => {
        const filters = {
            ...this.state.filters
        }
        Object.keys(filters).forEach(f => {
            filters[f] = typeof filters[f] === "string" ? "" : null;
        });

        this.setState({
            filters: filters
        });

        this.props.onResetAllFilters();
    }
    deleteHandler = () => {
        this.closeDeleteDialog();
        this.props.onDelete(this.state.deleteID);
    }

    openDeleteDialog = (deleteID) => {
        this.setState({
            deleteDialog: true,
            deleteID: deleteID,
        })
    }

    closeDeleteDialog = () => {
        this.setState({
            deleteDialog: false,
            deleteID: null,
        })
    }

    filterComments = () => {
        const filters = {
            ...this.state.filters
        };
        this.props.onFilterComments(filters);
    }

    componentDidMount() {
        this.setState({
            filters: this.props.filters
        });

    }
    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && this.props.activeDomain !== prevProps.activeDomain) {
            this.props.onChangeDomain();
        }

        return true;
    }

    render() {
        const commentsHTML = this.props.comments.map(data => {
            return <Comment {...data}
                key={data.id}
                delete={this.openDeleteDialog}
                setPassive={this.props.onDeactivate}
                setActive={this.props.onActivate} />;
        });
        const loading = this.props.loading ? <Loading size={100} /> : null;

        return (
            <React.Fragment>
                {loading}
                <Dialog
                    open={this.state.deleteDialog}
                    onClose={this.closeDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth>
                    <DialogContent>
                        <p>Yorum kalıcı olarak silinecektir. </p>
                        <p><strong>Onaylıyor musunuz?</strong></p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteDialog} color="primary">
                            İptal
                            </Button>
                        <Button onClick={this.deleteHandler} color="secondary" variant="contained">
                            SİL
                             </Button>
                    </DialogActions>
                </Dialog>
                <ExpansionPanel expanded={this.props.filtersExpanded} onChange={this.props.onChangeFiltersExpanded} className="GeneralFilter" CollapseProps={{
                    classes: {
                        container: "GeneralFilterContainer",
                    }
                }} classes={{ expanded: "GeneralFilterExpanded" }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h4 className="Title">Filtreler</h4>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="ExpansionDetails" >
                        <Grid container spacing={24}>

                            <Grid item xs={12} sm={6} >
                                <Input
                                    placeholder="Yorumda Ara"
                                    inputProps={{
                                        'aria-label': 'Description',
                                    }}
                                    className="GeneralInput"
                                    fullWidth
                                    onChange={(e) => { this.filterChangeHandler("title", e.target.value) }}
                                    value={this.state.filters.title}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Select
                                    changed={(val) => this.filterChangeHandler("status", val)}
                                    placeholder="Durum Seç"
                                    options={statuses}
                                    value={this.state.filters.status}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        <Button size="small" onClick={this.resetAllFilters}>Sıfırla</Button>
                        <Button size="small" color="primary" onClick={this.filterComments}>
                            Uygula
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <Grid container className="GeneralLists" spacing={24}>
                    <Grid item xs={12} >
                        <Paper className="LeftSide" elevation={1} square>
                            <table className="Comments">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Video</th>

                                        <th>Yorum</th>
                                        <th>Tarih</th>
                                        <th>İşlemler</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commentsHTML}
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                </Grid>
                <Pagination
                    size="large"
                    limit={this.props.pagination.limit}
                    offset={this.props.pagination.offset}
                    total={this.props.pagination.total}
                    onClick={(e, offset) => this.props.onChangePagination(offset)}
                    classes={{
                        root: "paginationLists"
                    }}
                />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        comments: state.comment.filteredComments.slice(state.comment.pagination.offset, state.comment.pagination.offset + state.comment.pagination.limit),
        pagination: state.comment.pagination,
        activeDomain: state.domain.activeDomain,
        loading: state.comment.loading,
        filters: state.comment.filters,
        filtersExpanded: state.comment.filtersExpanded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePagination: (offset) => {
            animateScroll.scrollToTop({ containerId: "scrolledContent", duration: 500, })
            dispatch(actions.changeCommentPagination(offset));
        },
        onChangeDomain: () => dispatch(actions.saveComments()),
        onFilterComments: (payload) => dispatch(actions.filterComments(payload)),
        onResetAllFilters: () => dispatch(actions.resetAllCommentsFilters()),
        onChangeFiltersExpanded: (e, expanded) => dispatch(actions.changeCommentFiltersExpanded(expanded)),
        onDelete: id => dispatch(actions.deleteComment(id)),
        onActivate: id => dispatch(actions.activateComment(id)),
        onDeactivate: id => dispatch(actions.deactivateComment(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comments);