import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import axios from '../../../axios';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loading from '../../../components/Loading/Loading';
import Snackbar from '@material-ui/core/Snackbar';

class TagEdit extends React.Component {
    state = {
        loading: null,
        error: null,
        seo: {
            titleLimit: 60,
            descriptionLimit: 230,
        },
        tag: {
            id: null,
            seoTitle: "",
            seoDescription: "",
            videosCount: 0,
            description: "",
        },
        redirect: {
            url: null,
            withUpdate: null,
        },
    }



    inputChangeHandler = (name, val) => {
        let tag = { ...this.state.tag, [name]: val };


        this.setState({
            tag: tag
        });
    }

    seoChangeHandler = (name, val) => {
        let seo = { ...this.state.seo };
        let inputName = "";
        if (name === "title") {
            if (seo.titleLimit < val.length) {
                return false;
            }
            inputName = "seoTitle";
        } else if (name === "description") {
            if (seo.descriptionLimit < val.length) {
                return false;
            }
            inputName = "seoDescription";
        }

        this.setState({
            seo: seo
        });
        this.inputChangeHandler(inputName, val);

    }


    getTag = () => {
        this.setState({ loading: true });
        const domainID = this.props.activeDomain.id;
        const id = this.props.match.params.id;

        const postData = {
            domainID: domainID,
            id: id,
        }

        axios.post("tag/getTag", postData)
            .then(response => {
                console.log(response);

                this.setState({
                    loading: false,
                    tag: {
                        ...this.state.tag,
                        id: response.data.id,
                        title: response.data.title,
                        seoTitle: response.data.seoTitle ? response.data.seoTitle : "",
                        seoDescription: response.data.seoDescription ? response.data.seoDescription : "",
                        description: response.data.description ? response.data.description : "",
                        videosCount: response.data.videosCount,
                    }

                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    redirect: {
                        url: "/tags",
                        withUpdate: null,
                    }
                })
            });
    }


    updateRequest = () => {
        this.setState({
            loading: true,
        });

        const postData = this.state.tag;
        postData.domainID = this.props.activeDomain.id;

        axios.post("tag/update", postData)
            .then(response => {
                this.setState({
                    loading: false,
                    redirect: {
                        url: "/tags"
                    }
                });

            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }

    componentDidMount() {
        if (this.props.activeDomain.id !== null) {
            this.getTag();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && this.props.activeDomain.id !== prevProps.activeDomain.id) {
            this.getTag();
        }
    }

    render() {
        const redirect = this.state.redirect.url ? <Redirect to={{
            pathname: this.state.redirect.url,
            state: {
                update: this.state.redirect.withUpdate
            }
        }} /> : null;


        const loading = this.state.loading ? <Loading size={100} /> : null;
        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }


        return (
            <div className="GeneralDetails">
                {redirect}
                {loading}
                {error}

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={1} xl={2} ></Grid>
                    <Grid item xs={12} sm={4} xl={3} >
                        <Paper className="LeftSide" elevation={1} square>

                            <Table >
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="th">Tag:</TableCell>
                                        <TableCell>{this.state.tag.title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="th">Video Sayısı:</TableCell>
                                        <TableCell>{this.state.tag.videosCount}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>

                        </Paper>

                    </Grid>
                    <Grid item xs={12} sm={6} xl={5} >

                        <Paper className="GeneralPaper" elevation={1} square>
                            <TextField
                                fullWidth
                                label="Seo Başlığı"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.tag.seoTitle}
                                onChange={(e) => this.seoChangeHandler("title", e.target.value)}
                                helperText={this.state.tag.seoTitle.length + " / " + this.state.seo.titleLimit}
                            />
                            <TextField
                                fullWidth
                                label="Seo Açıklaması"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={3}
                                value={this.state.tag.seoDescription}
                                margin="normal"
                                onChange={(e) => this.seoChangeHandler("description", e.target.value)}
                                helperText={this.state.tag.seoDescription.length + " / " + this.state.seo.descriptionLimit}
                            />

                            <TextField
                                fullWidth
                                label="Açıklama"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={3}
                                value={this.state.tag.description}
                                margin="normal"
                                onChange={(e) => this.inputChangeHandler("description", e.target.value)}
                            />
                        </Paper>
                        <Button style={{ display: "block", width: "100%", marginTop: "15px" }} onClick={this.updateRequest} variant="contained" color="primary" >
                            GÜNCELLE
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={1} xl={2} ></Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
    }
}


export default connect(mapStateToProps)(TagEdit);