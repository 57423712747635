import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';

import Schedule from "@material-ui/icons/Schedule";
import Create from "@material-ui/icons/Create";
import Check from "@material-ui/icons/Check";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbDown from "@material-ui/icons/ThumbDown";
import Visibility from "@material-ui/icons/Visibility";
import DesktopWindows from "@material-ui/icons/DesktopWindows";

import {Link} from 'react-router-dom';
import Downloading from '../../../assets/img/loading.png';
import Downloaded from '../../../assets/img/downloaded.png';
import DownloadError from '../../../assets/img/error.png';
import DownloadQueue from '../../../assets/img/stopwatch.png';

const video = (props) => {
    let status = "";

    switch (props.status) {
        case "scheduled":
            status = <Schedule/>;
            break;
        case "published":
            status = <Check/>;
            break;
        case "draft":
            status = <Create/>;
            break;
        case "extension":
            status = <DesktopWindows/>;
            break;
        default:
            status = "";
    }
    let miniVideo = null;
    if (props.playing) {

        miniVideo = (
            <div className="MiniVideo">
                <video autoPlay loop muted>
                    <source src={props.miniVideo}/>
                </video>
            </div>
        )

    }

    let downloadStatus = null;
    if ((props.downloadLogs && props.downloadLogs.download_status) || (props.downloadLogs && props.downloadLogs.download_status === 0)) {
        switch (props.downloadLogs.download_status) {
            case 0:
                downloadStatus = <img src={DownloadQueue} alt="Download Queue"/>
                break;
            case 1:
                downloadStatus = <img src={Downloading} alt="Downloading"/>
                break;
            case 2:
                downloadStatus = <img src={Downloaded} alt="Downloaded"/>
                break;
            case 4:
                downloadStatus = <img src={DownloadError} alt="Download Error"/>
                break;
        }
    }


    return (
        <Grid item xs={12} sm={4} lg={2} xl={2}>
            <Card className="Video">
                <Link to={"edit-video/" + props.id}>
                    <CardActionArea className="VideoActionArea" onMouseEnter={() => props.playVideo(props.orderKey)}
                                    onMouseLeave={() => props.stopVideo(props.orderKey)}>
                        <div className="Image">
                            <div className="Transparent"></div>
                            <img alt={props.title} src={props.cover}/>
                            <div className="Status">
                                {status}
                            </div>
                            <div className="Duration">
                                {props.duration}
                            </div>
                            {miniVideo}

                        </div>
                        <div className="Info">
                            <div className="Title">
                                <h2>{props.title}</h2>
                            </div>
                            {/*
                            <div className="Site">
                                {props.activeDomain}
                            </div>
                            */}
                            <div className="SubInfo">
                                <div className="Statistics">
                                    <div className="Statistic">
                                        <Visibility/> {props.statistics.views}
                                    </div>
                                    <div className="Statistic">
                                        <ThumbUp/> {props.statistics.likes}
                                    </div>
                                    <div className="Statistic">
                                        <ThumbDown/> {props.statistics.dislikes}
                                    </div>
                                    <div className="Statistic">
                                        {downloadStatus}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardActionArea>
                </Link>
            </Card>
        </Grid>
    );
}

export default video;