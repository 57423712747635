// @material-ui/icons
import Video from "@material-ui/icons/PlayCircleFilled";
import AddVideo from "@material-ui/icons/AddToPhotos";
import ViewModule from "@material-ui/icons/ViewModule";
import Star from "@material-ui/icons/Star";
import LiveTv from "@material-ui/icons/LiveTv";
import Settings from "@material-ui/icons/Settings";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Comment from "@material-ui/icons/Comment";
import Equalizer from "@material-ui/icons/Equalizer";
import Schedule from "@material-ui/icons/Schedule";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Copyright from "@material-ui/icons/Copyright";
import DoubleArrow from "@material-ui/icons/ArrowForward";
import Work from "@material-ui/icons/Work";
import Error from "@material-ui/icons/Error";
import EditSquare from "@material-ui/icons/Edit";
// import Code from "@material-ui/icons/Code";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
// core components/views
import AddVideoPage from "views/Videos/AddVideo/AddVideo.jsx";
import EditVideoPage from "views/Videos/EditVideo/EditVideo.jsx";
import VideosPage from "views/Videos/Videos.jsx";
import CategoriesPage from "views/Categories/Categories.jsx";
import CategoryEditPage from "views/Categories/Edit/Edit.jsx";
import PornstarsPage from "views/Pornstars/Pornstars.jsx";
import PornstarEditPage from "views/Pornstars/PornstarEdit/PornstarEdit.jsx";
import TagsPage from "views/Tags/Tags.jsx";
import TagEditPage from "views/Tags/TagEdit/TagEdit.jsx";
import ChannelsPage from "views/Channels/Channels.jsx";
import ChannelEditPage from "views/Channels/ChannelEdit/ChannelEdit.jsx";
import SettingsPage from "views/Settings/Settings.jsx";
import CommentsPage from "views/Comments/Comments.jsx";
import StatisticsPage from "views/Statistics/Statistics.jsx";
import DraftToSchedulePage from "views/DraftToSchedule/DraftToSchedule.jsx";
import Payments from "views/Payments/Payments.jsx";
import DmcaContents from "views/DmcaContents/DmcaContents.jsx";
import Redirects from "views/Redirects/Redirects.jsx";
import ToolsPage from "views/Tools/Tools.jsx";
import ErrorReports from "../views/ErrorReports/ErrorReports";
import BlogPage from "../views/Blog/Blog";
import BlogCategoriesPage from "../views/Blog/Categories/Categories";
import AddContent from "../views/Blog/AddContent/AddContent";
import EditContent from "../views/Blog/EditContent/EditContent";
// import UrlShortener from 'views/UrlShortener/UrlShortener.jsx';
const dashboardRoutes = [
    {
        path: "/videos",
        sidebarName: "Videolar",
        navbarName: "Videolar",
        icon: Video,
        component: VideosPage
    },
    {
        path: "/add-video",
        sidebarName: "Video Ekle",
        navbarName: "Video Ekle",
        icon: AddVideo,
        component: AddVideoPage
    },
    // {
    //   path: "/url-shortener",
    //   sidebarName: "Link Kısalt",
    //   navbarName: "Link Kısalt",
    //   icon: Code,
    //   component: UrlShortener
    // },
    {
        path: "/categories",
        sidebarName: "Kategoriler",
        navbarName: "Kategoriler",
        icon: ViewModule,
        component: CategoriesPage
    },
    {
        path: "/pornstars",
        sidebarName: "Yıldızlar",
        navbarName: "Yıldızlar",
        icon: Star,
        component: PornstarsPage
    },
    {
        path: "/channels",
        sidebarName: "Kanallar",
        navbarName: "Kanallar",
        icon: LiveTv,
        component: ChannelsPage
    },
    {
        path: "/tags",
        sidebarName: "Etiketler",
        navbarName: "Etiketler",
        icon: LocalOffer,
        component: TagsPage
    },
    {
        path: "/comments",
        sidebarName: "Yorumlar",
        navbarName: "Yorumlar",
        noMenu: true,
        icon: Comment,
        component: CommentsPage
    },
    {
        path: "/draft-to-schedule",
        sidebarName: "Taslakları Zamanla",
        navbarName: "Taslakları Zamanla",
        icon: Schedule,
        component: DraftToSchedulePage
    },
    {
        path: "/blog/add",
        navbarName: "İçerik Ekle",
        noMenu: true,
        component: AddContent
    },
    {
        path: "/blog/categories",
        navbarName: "Kategoriler",
        noMenu: true,
        component: BlogCategoriesPage
    },
    {
        path: "/blog/edit/:id",
        navbarName: "İçerik Düzenle",
        noMenu: true,
        component: EditContent
    },
    {
        path: "/blog",
        sidebarName: "Blog",
        navbarName: "Blog",
        icon: EditSquare,
        component: BlogPage
    },

    {
        path: "/settings",
        sidebarName: "Site Ayarları",
        navbarName: "Site Ayarları",
        icon: Settings,
        component: SettingsPage
    },
    {
        path: "/dmca-contents",
        sidebarName: "DMCA",
        navbarName: "DMCA",
        icon: Copyright,
        component: DmcaContents
    },
    {
        path: "/redirects",
        sidebarName: "301 Yönlendirmeleri",
        navbarName: "301 Yönlendirmeleri",
        icon: DoubleArrow,
        component: Redirects
    },
    {
        path: "/payments",
        sidebarName: "Ödemeler",
        navbarName: "Ödemeler",
        icon: AttachMoney,
        component: Payments
    },
    {
        path: "/statistics",
        sidebarName: "İstatistikler",
        navbarName: "İstatistikler",
        icon: Equalizer,
        component: StatisticsPage
    },
    {
        path: "/tools",
        sidebarName: "Araçlar",
        navbarName: "Araçlar",
        icon: Work,
        component: ToolsPage
    },

    {
        path: "/error-reports",
        navbarName: "Hata Raporları",
        component: ErrorReports,
        sidebarName: "Hata Raporları",
        icon: Error
    },
    {
        path: "/auth/logout",
        sidebarName: "Çıkış Yap",
        navbarName: "Çıkış Yap",
        icon: PowerSettingsNew
    },
    {
        path: "/edit-video/:id",
        navbarName: "Video Düzenle",
        noMenu: true,
        component: EditVideoPage
    },
    {
        path: "/edit-category/:id",
        navbarName: "Kategori Düzenle",
        noMenu: true,
        component: CategoryEditPage
    },
    {
        path: "/edit-pornstar/:id",
        navbarName: "Yıldız Düzenle",
        noMenu: true,
        component: PornstarEditPage
    },
    {
        path: "/edit-channel/:id",
        navbarName: "Kanal Düzenle",
        noMenu: true,
        component: ChannelEditPage
    },
    {
        path: "/edit-tag/:id",
        navbarName: "Etiket Düzenle",
        noMenu: true,
        component: TagEditPage
    },
    { redirect: true, path: "/", to: "/videos", navbarName: "Redirect" },
    { redirect: true, path: "/dashboard", to: "/videos", navbarName: "Redirect" }

];

export default dashboardRoutes;
