import React from 'react';

import { connect } from 'react-redux';

import * as actions from '../../store/actions';

import { Redirect } from 'react-router-dom';

class Logout extends React.Component {
    componentWillMount() {
        this.props.onLogout();
    }
    render() {
        return <Redirect to="/auth/login" />
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(actions.logout()),
    }
}

export default connect(null, mapDispatchToProps)(Logout);