import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import Loading from "../../components/Loading/Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "../../components/Select/Select";
import axios from "../../axios";

import * as actions from "../../store/actions";

class DmcaContents extends React.Component {
  state = {
    users: [],
    loading: false,
    error: null,
    contents: [],
    autoDmcaCounts: [],
    inputs: {
      deleteFromAllSites: null,
      urls: ""
    }
  };

  inputChangeHandler = (name, val) => {
    let inputs = { ...this.state.inputs, [name]: val };

    this.setState({
      inputs: inputs
    });
  };

  componentDidMount() {
    this.getDmcaContents();
    this.getAutoDmcaCounts();
  }


  getDmcaContents() {
    this.setState({
      loading: true
    });
    axios.post("dmca/get-contents")
      .then(response => {
        this.setState({
          loading: false,
          contents: response.data
        });
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  }

  getAutoDmcaCounts() {
    this.setState({
      loading: true
    });
    axios.post("dmca/get-auto-dmca-counts")
      .then(response => {
        this.setState({
          // loading: false,
          autoDmcaCounts: response.data
        });
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg
          // loading: false
        });
      });
  }

  submitForm = () => {
    this.setState({
      loading: true
    });

    const postData = this.state.inputs;

    axios.post("dmca/add-dmca-contents", postData)
      .then(response => {
        this.getDmcaContents();
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  };


  errorCloseHandler = () => {
    this.setState({
      error: null
    });
  };

  render() {
    let loading = null;

    if (this.state.loading) {
      loading = <Loading size={100} />;
    }

    let error = null;
    if (this.state.error) {
      error = (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.error !== null}
          autoHideDuration={5000}
          onClose={this.errorCloseHandler}
          ContentProps={{
            "aria-describedby": "message-id",
            "className": "snackbar danger"
          }}

          message={<span id="message-id">{this.state.error}</span>}
        />
      );
    }

    let contents = null;
    let autoDmcaCounts = null;

    if (this.props.activeUser.id !== 1) {
      return "Yetki hatası.";
    }
    if (this.state.contents.length > 0) {
      contents = this.state.contents.map(content => {
        return (
          <TableRow key={content.url}>
            <TableCell>{content.domain}</TableCell>
            <TableCell><a rel="noopener noreferrer" href={content.url} target="_blank">{content.url}</a></TableCell>

          </TableRow>
        );
      });
    }
    if (this.state.autoDmcaCounts.length > 0) {
      autoDmcaCounts = this.state.autoDmcaCounts.map(content => {
        return (
          <TableRow key={content.date}>
            <TableCell>{content.date}</TableCell>
            <TableCell>{content.count}</TableCell>

          </TableRow>
        );
      });
    }

    const deleteFromAllSitesOptions = [
      {
        label: "Evet",
        value: 1
      },
      {
        label: "Hayır",
        value: 0
      }

    ];


    return (
      <Grid container spacing={24}>

        {loading}
        {error}
        <Grid item xs={12} sm={12} lg={12}>
          <Grid container spacing={24}>

            <Grid item xs={12} sm={12} xl={5}>
              <Paper className="GeneralPaper" elevation={1} square>


                <TextField
                  fullWidth
                  label="Urller"
                  multiline
                  rows={10}
                  value={this.state.inputs.urls}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("urls", e.target.value)}
                  helperText={"Her satıra bir link gelecek şekilde TAM url giriniz"}
                />

                <Select
                  changed={(val) => this.inputChangeHandler("deleteFromAllSites", val)}
                  placeholder="-- Seçiniz --"
                  options={deleteFromAllSitesOptions}
                  value={this.state.inputs.deleteFromAllSites}
                  label="Tüm Sitelerdeki Aynı Videolar Kaldırılsın Mı?"
                />

              </Paper>


              <Button style={{ display: "block", width: "100%", marginTop: "15px" }}
                      onClick={this.submitForm}
                      variant="contained" color="primary">
                EKLE
              </Button>
              <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
              <Paper square className="GeneralPaper ">

                <Table className="ExoclickTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tarih</TableCell>
                      <TableCell>Silinen İçerik Sayısı</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {autoDmcaCounts}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} xl={7}>
              <Paper square className="GeneralPaper ">

                <Table className="ExoclickTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>Domain</TableCell>
                      <TableCell>Url</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contents}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

        </Grid>
      </Grid>
    );
  }
}


const mapStateToProps = state => {
  return {
    activeDomain: state.domain.activeDomain,
    activeUser: state.auth.user
  };
};

const mapStateToDispatch = dispatch => {
  return {
    onChangeDomain: () => dispatch(actions.saveSettings())
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DmcaContents); 