import React from 'react';
import Video from './Video/Video';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';

import Select from '../../components/Select/Select';
import Pagination from "material-ui-flat-pagination";

import Loading from '../../components/Loading/Loading';

import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Schedule from "@material-ui/icons/Schedule";
import Create from "@material-ui/icons/Create";
import Check from "@material-ui/icons/Check";
import DesktopWindows from "@material-ui/icons/DesktopWindows";

import * as actions from '../../store/actions';
// import {DescriptionTwoTone} from "@material-ui/icons";

const statuses = [
    {
        label: "Taslak",
        value: 1,
    },
    {
        label: "Paylaşılmış",
        value: 2,
    },
    {
        label: "Zamanlanmış",
        value: 3,
    },
    {
        label: "Eklenti",
        value: 4,
    },
]
const downloadStatuses = [
    {
        label: "Kuyrukta",
        value: 0,
    },
    {
        label: "İndiriliyor",
        value: 1,
    },
    {
        label: "İndirildi",
        value: 2,
    },
    {
        label: "İndirme Hatası",
        value: 4,
    },
]

const sortingList = [
    {
        label: "Yeniden Eskiye",
        value: "hottest",
    },
    {
        label: "Eskiden Yeniye",
        value: "oldest",
    },
    {
        label: "Son Güncellenmeye Göre",
        value: "updated_at",
    },
    {
        label: "Trend Puanına Göre",
        value: "trending",
    },
    {
        label: "En Uzunlar",
        value: "longest",
    },
    {
        label: "En Kısalar",
        value: "shortest",
    },
    {
        label: "En Beğenilenler - Bugün",
        value: "mostLikedD",
    },
    {
        label: "En Beğenilenler - Bu Hafta",
        value: "mostLikedW",
    },
    {
        label: "En Beğenilenler - Bu Ay",
        value: "mostLikedM",
    },
    {
        label: "En Beğenilenler - Tüm Zamanlar",
        value: "mostLikedA",
    },
    {
        label: "En Çok İzlenenler - Bugün",
        value: "mostViewedD",
    },
    {
        label: "En Çok İzlenenler - Bu Hafta",
        value: "mostViewedW",
    },
    {
        label: "En Çok İzlenenler - Bu Ay",
        value: "mostViewedM",
    },
    {
        label: "En Çok İzlenenler - Tüm Zamanlar",
        value: "mostViewedA",
    },

]

class Videos extends React.Component {
    state = {
        filters: {
            category: null,
            title: "",
            status: null,
            downloadStatus: null,
            sort: null,
            pornstar: null,
            channel: null,
            tag: null,
        },
        playingVideoKey: null,
    }

    filterChangeHandler = (name, val) => {
        const filters = {...this.state.filters, [name]: val};
        this.setState({
            filters: filters
        });
    }

    filterMultiChangeHandler = (name, val) => {
        const values = val.map(v => {
            return v.value;
        });
        let filters = {...this.state.filters, [name]: values};
        this.setState({
            filters: filters
        });
    }

    findVideosWithFilters = e => {
        e.preventDefault();
        const filters = {
            ...this.state.filters,
            offset: 0,
        }

        this.props.onFindVideosWithFilters(filters);


    }

    resetAllFilters = () => {
        const filters = {
            ...this.state.filters
        }
        Object.keys(filters).forEach(f => {
            filters[f] = typeof filters[f] === "string" ? "" : null;
        });

        this.setState({
            filters: filters
        });

        this.props.resetAllFilters();
    }


    playVideoHandler = (key) => {
        this.setState({
            playingVideoKey: key,
        });

    }

    stopVideoHandler = (key) => {
        this.setState({
            playingVideoKey: null,
        });

    }

    errorCloseHandler = () => {
        this.props.onCloseError();
    }

    componentDidMount() {
        if (
            (this.props.activeDomain.id !== null && this.props.videos.length < 1)
            ||
            (this.props.location.state && this.props.location.state.updateVideos)
        ) {
            this.props.onGetVideos();
            if (this.props.location.state && this.props.location.state.updateVideos) {
                this.props.history.replace({
                    pathname: '/videos',
                    state: {}
                })
            }
        }


        this.setState({
            filters: this.props.filters
        })

    }

    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && this.props.activeDomain !== prevProps.activeDomain) {

            this.props.onGetVideos();
        }

        return true;
    }

    render() {

        let videos = Object.keys(this.props.videos).map(key => {
            return <Video
                activeDomain={this.props.activeDomain.title}
                {...this.props.videos[key]}
                orderKey={key}
                playing={this.state.playingVideoKey === key}
                key={this.props.videos[key].id}
                playVideo={this.playVideoHandler}
                stopVideo={this.stopVideoHandler}/>;
        });
        if (videos.length < 1) {
            videos = <h2 style={{textAlign: "center", display: "block", width: "100%"}}>Bu domaine ait video
                bulunamadı.</h2>;
        }


        const loading = this.props.loading ? <Loading size={100}/> : null;
        let error = null;
        if (this.props.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.props.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar danger"
                    }}

                    message={<span id="message-id">{this.props.error}</span>}
                />
            );
        }
        return (
            <React.Fragment>
                {loading}
                {error}
                <div className="VideoStats">

                    <div className="Stat">
                        <DesktopWindows/> {this.props.stats.extension}
                    </div>
                    <div className="Stat">
                        <Create/> {this.props.stats.draft}
                    </div>
                    <div className="Stat">
                        <Check/> {this.props.stats.published}
                    </div>
                    <div className="Stat">
                        <Schedule/> {this.props.stats.scheduled}
                    </div>


                </div>
                <form onSubmit={this.findVideosWithFilters} className="VideosFilter">
                    <ExpansionPanel expanded={this.props.filtersExpanded} onChange={this.props.onChangeFiltersExpanded}
                                    className="VideosFilter" CollapseProps={{
                        classes: {
                            container: "VideosFilterContainer",
                        }
                    }} classes={{expanded: "VideosFilterExpanded"}}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <h4 className="Title">Filtreler</h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="ExpansionDetails">

                            <Grid container className="Videos" spacing={24}>

                                <Grid item xs={12} sm={3}>
                                    <Input
                                        placeholder="Başlık"
                                        inputProps={{
                                            'aria-label': 'Description',
                                        }}
                                        className="GeneralInput"
                                        fullWidth
                                        onChange={(e) => {
                                            this.filterChangeHandler("title", e.target.value)
                                        }}
                                        value={this.state.filters.title}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("category", val)}
                                        placeholder="Kategori Ara"
                                        // options={categories}
                                        value={this.state.filters.category}
                                        ajaxUrl="category/searchCategory"
                                        domainID={this.props.activeDomain.id}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("status", val)}
                                        placeholder="Durum Seç"
                                        options={statuses}
                                        value={this.state.filters.status}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("downloadStatus", val)}
                                        placeholder="İndirme Durumu Seç"
                                        options={downloadStatuses}
                                        value={this.state.filters.downloadStatus}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("sort", val)}
                                        placeholder="Sıralama Seç"
                                        options={sortingList}
                                        value={this.state.filters.sort}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("pornstar", val)}
                                        placeholder="Yıldız Seç "
                                        // options={pornstars}
                                        ajaxUrl="pornstar/searchPornstar"
                                        domainID={this.props.activeDomain.id}
                                        value={this.state.filters.pornstar}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("channel", val)}
                                        placeholder="Kanal Seç"
                                        // options={channels}
                                        value={this.state.filters.channel}
                                        ajaxUrl="channel/searchChannel?excludeNew=true"
                                        domainID={this.props.activeDomain.id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Select
                                        changed={(val) => this.filterChangeHandler("tag", val)}
                                        placeholder="Etiket Seç"
                                        // options={channels}
                                        value={this.state.filters.tag}
                                        ajaxUrl="tag/searchTag"
                                        domainID={this.props.activeDomain.id}
                                    />
                                </Grid>
                            </Grid>


                        </ExpansionPanelDetails>
                        <Divider/>
                        <ExpansionPanelActions>
                            <Button size="small" onClick={this.resetAllFilters}>Sıfırla</Button>
                            <Button type="submit" size="small" color="primary">
                                Uygula
                            </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </form>
                <Grid container className="Videos" spacing={24}>
                    {videos}
                </Grid>
                <Pagination
                    size="large"
                    limit={this.props.pagination.limit}
                    offset={this.props.pagination.offset}
                    total={this.props.pagination.total}
                    onClick={(e, offset) => this.props.onChangePage(offset)}
                    classes={{
                        root: "paginationLists"
                    }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
        categories: state.category.categories,
        pornstars: state.pornstar.pornstars,
        channels: state.channel.channels,
        loading: state.video.loading,
        videos: state.video.videos,
        error: state.video.error,
        pagination: state.video.pagination,
        filters: state.video.filters,
        filtersExpanded: state.video.filtersExpanded,
        stats: state.video.stats,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetVideos: () => dispatch(actions.getVideos()),
        onCloseError: () => dispatch(actions.clearVideoError()),
        onChangePage: (offset) => dispatch(actions.changeVideoPage(offset)),
        onFindVideosWithFilters: (payload) => dispatch(actions.getVideos(payload)),
        onChangeFiltersExpanded: (e, expanded) => dispatch(actions.changeFiltersExpanded(expanded)),
        resetAllFilters: () => dispatch(actions.resetAllVideosFilters()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos);