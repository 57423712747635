export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_WITH_TOKEN_FAIL = "AUTH_WITH_TOKEN_FAIL";
export const LOGOUT = "LOGOUT";

export const SAVE_DOMAINS = "SAVE_DOMAINS";
export const SAVE_DEFAULT_DOMAIN = "SAVE_DEFAULT_DOMAIN";
export const CHANGE_DEFAULT_DOMAIN = "CHANGE_DEFAULT_DOMAIN";

export const CHANGE_CATEGORY_FILTERS_EXPANDED = "CHANGE_CATEGORY_FILTERS_EXPANDED";
export const RESET_ALL_CATEGORIES_FILTERS = "RESET_ALL_CATEGORIES_FILTERS";
export const SAVE_CATEGORIES = "SAVE_CATEGORIES";
export const SAVE_CATEGORIES_START = "SAVE_CATEGORIES_START";
export const CHANGE_CATEGORY_PAGINATION = "CHANGE_CATEGORY_PAGINATION";
export const SAVE_FILTERED_CATEGORIES = "SAVE_FILTERED_CATEGORIES";

export const CHANGE_PORNSTAR_FILTERS_EXPANDED = "CHANGE_PORNSTAR_FILTERS_EXPANDED";
export const RESET_ALL_PORNSTARS_FILTERS = "RESET_ALL_PORNSTARS_FILTERS";
export const SAVE_PORNSTARS = "SAVE_PORNSTARS";
export const SAVE_PORNSTARS_START = "SAVE_PORNSTARS_START";
export const CHANGE_PORNSTAR_PAGINATION = "CHANGE_PORNSTAR_PAGINATION";
export const SAVE_FILTERED_PORNSTARS = "SAVE_FILTERED_PORNSTARS";

export const CHANGE_CHANNEL_FILTERS_EXPANDED = "CHANGE_CHANNEL_FILTERS_EXPANDED";
export const RESET_ALL_CHANNELS_FILTERS = "RESET_ALL_CHANNELS_FILTERS";
export const SAVE_CHANNELS = "SAVE_CHANNELS";
export const SAVE_CHANNELS_START = "SAVE_CHANNELS_START";
export const CHANGE_CHANNEL_PAGINATION = "CHANGE_CHANNEL_PAGINATION";
export const SAVE_FILTERED_CHANNELS = "SAVE_FILTERED_CHANNELS";

export const CHANGE_COMMENT_FILTERS_EXPANDED = "CHANGE_COMMENT_FILTERS_EXPANDED";
export const RESET_ALL_COMMENTS_FILTERS = "RESET_ALL_COMMENTS_FILTERS";
export const SAVE_COMMENTS = "SAVE_COMMENTS";
export const SAVE_COMMENTS_START = "SAVE_COMMENTS_START";
export const CHANGE_COMMENT_PAGINATION = "CHANGE_COMMENT_PAGINATION";
export const SAVE_FILTERED_COMMENTS = "SAVE_FILTERED_COMMENTS";

export const SAVE_TAGS = "SAVE_TAGS";

export const RESET_ALL_VIDEOS_FILTERS = "RESET_ALL_VIDEOS_FILTERS";
export const SAVE_VIDEOS = "SAVE_VIDEOS";
export const SAVE_VIDEOS_START = "SAVE_VIDEOS_START";
export const SAVE_VIDEOS_SUCCESS = "SAVE_VIDEOS_SUCCESS";
export const SAVE_VIDEOS_ERROR = "SAVE_VIDEOS_ERROR";
export const REFRESH_VIDEOS_SUCCESS = "REFRESH_VIDEOS_SUCCESS";
export const CLEAR_VIDEO_ERROR = "CLEAR_VIDEO_ERROR";
export const CHANGE_FILTERS_EXPANDED = "CHANGE_FILTERS_EXPANDED";

export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const SAVE_SETTINGS_START = "SAVE_SETTINGS_START";
export const CHANGE_ACTIVE_SETTINGS_TAB = "CHANGE_ACTIVE_SETTINGS_TAB";

export const SAVE_CONFIGS = "SAVE_CONFIGS";
export const SAVE_CONFIGS_START = "SAVE_CONFIGS_START";

