import LoginPage from 'views/Login/Login.jsx';
import Logout from 'views/Logout/Logout.jsx';

const authRoutes = [
  {
    path: "/auth/login",
    component: LoginPage
  },
  {
    path: "/auth/logout",
    component: Logout,
  },
  { redirect: true, path: "/auth/", to: "/auth/login" }
];

export default authRoutes;
