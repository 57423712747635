import * as actionTypes from '../actions/actionTypes';

const initialState = {
    tags: [],
    loading: null,
    error: null,
}

const tags = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_TAGS:
            return {
                ...state,
                tags: action.tags,
            }
        default:
            return state;
    }
}

export default tags;