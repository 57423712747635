import React from "react";
import Grid from "@material-ui/core/Grid";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Select from "../../components/Select/Select";
import Pagination from "material-ui-flat-pagination";

import Loading from "../../components/Loading/Loading";

import { connect } from "react-redux";
import axios from "../../axios";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Snackbar from "@material-ui/core/Snackbar";

const errorCodes = [
  {
    label: "Telif",
    value: "copyright"
  },
  {
    label: "Hata",
    value: "error"
  },
  {
    label: "Minör",
    value: "minor"
  },
  {
    label: "Diğer",
    value: "others"
  }
];


class ErrorReports extends React.Component {

  state = {
    filters: {
      errorCode: null
    },
    pagination: {
      limit: 25,
      offset: 0,
      total: 0
    },
    errorReports: []
  };

  filterChangeHandler = (name, val) => {
    let filters = { ...this.state.filters, [name]: val };
    this.setState({
      filters: filters
    });

  };

  resetAllFilters = () => {
    const filters = {
      ...this.state.filters
    };
    Object.keys(filters).forEach(f => {
      filters[f] = typeof filters[f] === "string" ? "" : null;
    });

    this.setState({
      filters: filters
    });
    setTimeout(() => {
      this.getErrorReports();
    }, 200);
  };


  filterErrorReports = e => {
    e.preventDefault();
    this.getErrorReports();
  };

  getErrorReports = () => {
    this.setState({
      loading: true
    });
    axios.post("error-reports/get-error-reports", {
      limit: this.state.pagination.limit,
      offset: this.state.pagination.offset,
      errorCode: this.state.filters.errorCode
    })
      .then(response => {
        this.setState({
          loading: false,
          errorReports: response.data.errorReports,
          pagination: {
            ...this.state.pagination,
            total: response.data.total
          }
        });
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  };

  changePagination = offset => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        offset: offset
      }
    });
    setTimeout(() => {
      this.getErrorReports();
    }, 200);
  };


  componentDidMount() {
    this.getErrorReports();
  }

  errorCloseHandler = () => {
    this.setState({
      error: null
    });
  };

  render() {
    console.log(this.state);

    //
    // const channelsHTML = this.props.channels.map(data => {
    //   return <Channel {...data} key={data.id} />;
    // });


    const loading = this.state.loading ? <Loading size={100} /> : null;


    let error = null;
    if (this.state.error) {
      error = (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.error !== null}
          autoHideDuration={5000}
          onClose={this.errorCloseHandler}
          ContentProps={{
            "aria-describedby": "message-id",
            "className": "snackbar danger"
          }}

          message={<span id="message-id">{this.state.error}</span>}
        />
      );
    }
    let tabContent;
    let contents = null;

    if (this.props.activeUser.id !== 1) {
      return "Yetki hatası.";
    }
    if (this.state.errorReports.length > 0) {
      contents = this.state.errorReports.map(content => {

        const type = errorCodes.filter(c => c.value === content.error_code)[0].label;

        return (
          <TableRow key={content.id}>
            <TableCell>{content.date}</TableCell>
            <TableCell><a rel="noopener noreferrer" href={content.url} target="_blank">{content.url}</a></TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{content.note}</TableCell>

          </TableRow>
        );
      });
    }
    console.log(this.state);
    tabContent = (
      <React.Fragment>
        <Paper square className="GeneralPaper ">

          <Table className="ExoclickTable">
            <TableHead>
              <TableRow>
                <TableCell>Tarih</TableCell>
                <TableCell>Url</TableCell>
                <TableCell>Tür</TableCell>
                <TableCell>Mesaj</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contents}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {loading}

        {error}
        <form onSubmit={this.filterErrorReports} className="GeneralFilter">
          <ExpansionPanel expanded={true}
                          className="GeneralFilter" CollapseProps={{
            classes: {
              container: "GeneralFilterContainer"
            }
          }} classes={{ expanded: "GeneralFilterExpanded" }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h4 className="Title">Filtreler</h4>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="ExpansionDetails">
              <Grid container spacing={24}>


                <Grid item xs={12} sm={12}>
                  <Select
                    changed={(val) => this.filterChangeHandler("errorCode", val)}
                    placeholder="Durum Seç"
                    options={errorCodes}
                    value={this.state.filters.errorCode}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
              <Button size="small" onClick={this.resetAllFilters}>Sıfırla</Button>
              <Button size="small" color="primary" type="submit">
                Uygula
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </form>
        <Grid container className="GeneralLists" spacing={24}>
          <Grid item xs={12} sm={12} xl={12}>
            {tabContent}
          </Grid>
        </Grid>
        <Pagination
          size="large"
          limit={this.state.pagination.limit}
          offset={this.state.pagination.offset}
          total={this.state.pagination.total}
          onClick={(e, offset) => this.changePagination(offset)}
          classes={{
            root: "paginationLists"
          }}
        />
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => {
  return {
    activeUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorReports);