import * as actionTypes from './actionTypes';
import axios from '../../axios';
import {
    compare
} from '../helper';

export const saveCategories = () => {
    return (dispatch, getState) => {
        dispatch(saveCategoriesStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
        }
        axios.post("category/getCategories", postData)
            .then(response => {
                dispatch(saveCategoriesToState(response.data));
                dispatch(filterCategories());
            })
    }
}

export const saveCategoriesToState = (categories) => {
    return {
        type: actionTypes.SAVE_CATEGORIES,
        categories: categories,
    }
}

export const changeCategoryPagination = (offset) => {
    return {
        type: actionTypes.CHANGE_CATEGORY_PAGINATION,
        offset: offset,
    }
}

export const saveCategoriesStart = () => {
    return {
        type: actionTypes.SAVE_CATEGORIES_START,
    }
}

export const filterCategories = (payload) => {
    if (!payload) payload = {};

    return (dispatch, getState) => {
        payload.status = payload && payload.status ? payload.status : getState().category.filters.status;
        payload.title = payload && payload.title ? payload.title : getState().category.filters.title;
        payload.sort = payload && payload.sort ? payload.sort.value : getState().category.filters.sort;
        let categories = getState().category.categories;
        if (payload.status) {
            categories = categories.filter(category => {
                return category.isActive === (payload.status.value === 1)
            });
        }

        if (payload.title) {
            categories = categories.filter(category => {
                return category.title.toLowerCase().indexOf(payload.title.toLowerCase()) !== -1;
            });
        }

        if (payload.sort) {
            switch (payload.sort) {
                case "videosCountAsc":
                    compare(categories, "videosCount", true);
                    break;
                case "videosCountDesc":
                    compare(categories, "videosCount", false);
                    break;
                case "popularPoint":
                    compare(categories, "popularPoint", false);
                    break;
                case "nameAsc":
                    compare(categories, "title", true);
                    break;
                case "nameDesc":
                    compare(categories, "title", false);
                    break;
                default:
                    compare(categories, "title", true);
                    break;
            }
        }



        dispatch(saveFilteredCategories(categories, payload));
    }
}

export const saveFilteredCategories = (categories, filters) => {
    return {
        type: actionTypes.SAVE_FILTERED_CATEGORIES,
        categories: categories,
        filters: filters,
    }
}

export const resetAllCategoriesFilters = () => {
    return {
        type: actionTypes.RESET_ALL_CATEGORIES_FILTERS,
    }
}


export const changeCategoryFiltersExpanded = (expanded) => {
    return {
        type: actionTypes.CHANGE_CATEGORY_FILTERS_EXPANDED,
        expanded: expanded,
    }
}