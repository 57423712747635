import React from 'react';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';

import Select from '../../components/Select/Select';
import Pagination from "material-ui-flat-pagination";
import Channel from './Channel/Channel';

import Loading from '../../components/Loading/Loading';
import * as actions from '../../store/actions';
import { animateScroll } from 'react-scroll';

import { connect } from 'react-redux';

const statuses = [
    {
        label: "Aktif",
        value: 1,
    },
    {
        label: "Pasif",
        value: 0,
    },
]


const sortingList = [
    {
        label: "Video Sayısı (Azalan)",
        value: "videosCountDesc",
    },
    {
        label: "Video Sayısı (Artan)",
        value: "videosCountAsc",
    },
    {
        label: "En Popüler",
        value: "popularPoint",
    },
    {
        label: "En Çok İzlenen",
        value: "viewCount",
    },
    {
        label: "A-Z",
        value: "nameAsc",
    },
    {
        label: "Z-A",
        value: "nameDesc",
    },
    {
        label: "En Çok Abone Sahibi",
        value: "subscriberCount",
    },
]


class Channels extends React.Component {

    state = {
        filters: {
            title: "",
            status: null,
        },
    }

    filterChangeHandler = (name, val) => {
        let filters = { ...this.state.filters, [name]: val };
        this.setState({
            filters: filters
        });

    }

    resetAllFilters = () => {
        const filters = {
            ...this.state.filters
        }
        Object.keys(filters).forEach(f => {
            filters[f] = typeof filters[f] === "string" ? "" : null;
        });

        this.setState({
            filters: filters
        });

        this.props.onResetAllFilters();
    }


    filterChannels = e => {
        e.preventDefault();
        const filters = {
            ...this.state.filters
        };
        this.props.onFilterChannels(filters);
    }


    componentDidMount() {
        if (this.props.activeDomain.id !== null) {
            this.props.onGetChannels();
            this.setState({
                filters: this.props.filters
            });
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && prevProps.activeDomain.id !== null && this.props.activeDomain !== prevProps.activeDomain) {
            this.props.onChangeDomain();
        }

        if (this.props.activeDomain.id !== null && this.props.activeDomain.id !== prevProps.activeDomain.id) {
            this.props.onGetChannels();
            this.setState({
                filters: this.props.filters
            });
        }

        return true;
    }

    render() {


        const channelsHTML = this.props.channels.map(data => {
            return <Channel {...data} key={data.id} />;
        });

        const loading = this.props.loading ? <Loading size={100} /> : null;

        return (
            <React.Fragment>
                {loading}
                <form onSubmit={this.filterChannels} className="GeneralFilter">
                    <ExpansionPanel expanded={this.props.filtersExpanded} onChange={this.props.onChangeFiltersExpanded} className="GeneralFilter" CollapseProps={{
                        classes: {
                            container: "GeneralFilterContainer",
                        }
                    }} classes={{ expanded: "GeneralFilterExpanded" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <h4 className="Title">Filtreler</h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="ExpansionDetails" >
                            <Grid container spacing={24}>


                                <Grid item xs={12} sm={4} >
                                    <Input
                                        placeholder="Başlık"
                                        inputProps={{
                                            'aria-label': 'Description',
                                        }}
                                        className="GeneralInput"
                                        fullWidth
                                        onChange={(e) => { this.filterChangeHandler("title", e.target.value) }}
                                        value={this.state.filters.title}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <Select
                                        changed={(val) => this.filterChangeHandler("status", val)}
                                        placeholder="Durum Seç"
                                        options={statuses}
                                        value={this.state.filters.status}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <Select
                                        changed={(val) => this.filterChangeHandler("sort", val)}
                                        placeholder="Sıralama Seç"
                                        options={sortingList}
                                        value={this.state.filters.sort}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button size="small" onClick={this.resetAllFilters}>Sıfırla</Button>
                            <Button size="small" color="primary" type="submit">
                                Uygula
                        </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </form>
                <Grid container className="GeneralLists" spacing={24}>
                    {channelsHTML}
                </Grid>
                <Pagination
                    size="large"
                    limit={this.props.pagination.limit}
                    offset={this.props.pagination.offset}
                    total={this.props.pagination.total}
                    onClick={(e, offset) => this.props.onChangePagination(offset)}
                    classes={{
                        root: "paginationLists"
                    }}
                />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        channels: state.channel.filteredChannels.slice(state.channel.pagination.offset, state.channel.pagination.offset + state.channel.pagination.limit),
        pagination: state.channel.pagination,
        activeDomain: state.domain.activeDomain,
        loading: state.channel.loading,
        filters: state.channel.filters,
        filtersExpanded: state.channel.filtersExpanded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePagination: (offset) => {
            animateScroll.scrollToTop({ containerId: "scrolledContent", duration: 500, })
            dispatch(actions.changeChannelPagination(offset));
        },
        onChangeDomain: () => dispatch(actions.saveChannels()),
        onGetChannels: () => dispatch(actions.saveChannels()),
        onFilterChannels: (payload) => dispatch(actions.filterChannels(payload)),
        onResetAllFilters: () => dispatch(actions.resetAllChannelsFilters()),
        onChangeFiltersExpanded: (e, expanded) => dispatch(actions.changeChannelFiltersExpanded(expanded)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Channels);