import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Equalizer from "@material-ui/icons/Equalizer";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import Loading from "../../components/Loading/Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "../../axios";

import * as actions from "../../store/actions";

function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

const current_datetime = new Date();
const today = current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate());


class Statistics extends React.Component {
  state = {
    activeTab: 0,
    seo: {
      titleLimit: 60,
      descriptionLimit: 230
    },
    exoStartDate: today,
    exoEndDate: today,
    loading: false,
    error: null,
    exoclick: {
      dates: [],
      total: {}
    },
    analytics: []
  };

  changeTabHandle = (event, value) => {
    this.setState({
      activeTab: value
    });
  };

  inputChangeHandler = (name, val) => {

    this.setState({
      [name]: val
    });
  };

  getAnalytics = force => {

    this.setState({
      loading: true
    });

    axios.post("statistics/getAnalytics", {
      domainID: this.props.activeDomain.id
    })
      .then(response => {
        if (response.status !== 200) {
          if (response.data.error) {
            this.setState({
              "error": response.data.error
            });
          } else {
            this.setState({
              "error": "Bir hata oluştu"
            });
          }
        } else {
          this.setState({
            analytics: response.data
          });
        }
        this.setState({
          loading: false
        });
      });
  };

  getExoclickStatistics = e => {
    if (e) {
      e.preventDefault();
    }

    // if (!this.props.activeDomain.id) {
    //     return false;
    // }

    const startDate = this.state.exoStartDate;
    const endDate = this.state.exoEndDate;
    this.setState({
      loading: true
    });

    axios.post("statistics/getExoclickStatistics", {
      domainID: this.props.activeDomain.id,
      startDate,
      endDate
    })
      .then(response => {
        console.log("hata", response);
        if (response.status !== 200) {
          if (response.data.error) {
            this.setState({
              "error": response.data.error
            });
          } else {
            this.setState({
              "error": "Bir hata oluştu"
            });
          }
        } else {
          this.setState({
            ...this.state,
            exoclick: {
              ...this.state.exoclick,
              dates: response.data.dates,
              total: response.data.total
            }
          });
        }
        this.setState({
          loading: false
        });
      }).catch(err => {
      if (err.response.data.error) {
        this.setState({
          "error": err.response.data.error,
          loading: false
        });
      } else {
        this.setState({
          "error": "Bir hata oluştu",
          loading: false
        });
      }
    });
  };

  componentDidMount() {
    if (this.props.activeDomain.id) {
      this.getAnalytics();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeDomain.id !== null && this.props.activeDomain !== prevProps.activeDomain) {
      this.props.onChangeDomain();
      this.getAnalytics();
    }

    return true;
  }

  errorCloseHandler = () => {
    this.setState({
      error: null
    });
  };

  render() {
    console.log(this.state);
    let tabContent = null;

    let loading = null;

    if (this.state.loading) {
      loading = <Loading size={100} />;
    }

    let error = null;
    if (this.state.error) {
      error = (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.error !== null}
          autoHideDuration={5000}
          onClose={this.errorCloseHandler}
          ContentProps={{
            "aria-describedby": "message-id",
            "className": "snackbar danger"
          }}

          message={<span id="message-id">{this.state.error}</span>}
        />
      );
    }

    switch (this.state.activeTab) {

      case 0: // General
        let dates = null;
        let total = null;
        let table = null;

        if (this.state.exoclick.dates.length > 0) {
          dates = this.state.exoclick.dates.map(date => {
            return (
              <TableRow key={date.date}>
                <TableCell>{date.date}</TableCell>
                <TableCell align="right">{date.clicks}</TableCell>
                <TableCell align="right">{date.impressions}</TableCell>
                <TableCell align="right">{date.cpm}</TableCell>
                <TableCell align="right">{date.videoImpressions}</TableCell>
                <TableCell align="right">${date.revenue}</TableCell>
              </TableRow>
            );
          });
          total = (
            <TableRow key={"totalExo"} className="totalRow">
              <TableCell>Toplam:</TableCell>
              <TableCell align="right">{this.state.exoclick.total.clicks}</TableCell>
              <TableCell align="right">{this.state.exoclick.total.impressions}</TableCell>
              <TableCell align="right">{this.state.exoclick.total.cpm}</TableCell>
              <TableCell align="right">{this.state.exoclick.total.videoImpressions}</TableCell>
              <TableCell align="right">${this.state.exoclick.total.revenue}</TableCell>
            </TableRow>
          );
          table = (
            <Table className="ExoclickTable">
              <TableHead>
                <TableRow>
                  <TableCell>Tarih</TableCell>
                  <TableCell align="right">Tıklama</TableCell>
                  <TableCell align="right">Etkileşim</TableCell>
                  <TableCell align="right">CPM</TableCell>
                  <TableCell align="right">Video Etkileşim</TableCell>
                  <TableCell align="right">Kazanç</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dates}
                {total}
              </TableBody>
            </Table>
          );
        }
        tabContent = (
          <React.Fragment>
            <Paper square className="GeneralPaper ">
              <form onSubmit={this.getExoclickStatistics} className="ExoclickFilter">

                <Grid container spacing={24}>

                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="date"
                      label="Başlangıç"
                      type="date"
                      fullWidth
                      onChange={e => (this.inputChangeHandler("exoStartDate", e.target.value))}
                      value={this.state.exoStartDate}
                      inputProps={{
                        max: today
                      }}
                      InputLabelProps={{
                        shrink: true

                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="date"
                      label="Bitiş"
                      type="date"
                      fullWidth
                      onChange={e => (this.inputChangeHandler("exoEndDate", e.target.value))}
                      value={this.state.exoEndDate}
                      inputProps={{
                        max: today,
                        min: this.state.exoStartDate
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button type="submit" size="large" variant="contained" fullWidth color="primary">
                      Uygula
                    </Button>
                  </Grid>

                </Grid>


              </form>

              <Divider />
              {table}
            </Paper>
          </React.Fragment>
        );
        break;
      case 1: // General
        let analytics = null;

        if (this.state.analytics.length > 0) {
          analytics = this.state.analytics.map(analytic => {
            return (
              <TableRow key={analytic.month}>
                <TableCell>{analytic.month}</TableCell>
                <TableCell align="center">{analytic.total_users}</TableCell>
                <TableCell align="center">{analytic.new_users}</TableCell>
                <TableCell align="center">{analytic.direct}</TableCell>
                <TableCell align="center">{analytic.social}</TableCell>
                <TableCell align="center">{analytic.organic}</TableCell>
                <TableCell align="center">{analytic.bounce_rate}</TableCell>
                <TableCell align="center">{analytic.avg_duration}</TableCell>
                <TableCell align="center">{analytic.sessions_per_user}</TableCell>
                <TableCell align="center">${analytic.revenue}</TableCell>
                <TableCell align="center">{analytic.video_count}</TableCell>
              </TableRow>
            );
          });
        }
        tabContent = (
          <React.Fragment>
            <Paper square className="GeneralPaper ">

              <Table className="ExoclickTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Dönem</TableCell>
                    <TableCell align="center">Toplam Kullanıcı</TableCell>
                    <TableCell align="center">Yeni Kullanıcı</TableCell>
                    <TableCell align="center">Direkt</TableCell>
                    <TableCell align="center">Sosyal</TableCell>
                    <TableCell align="center">Organik</TableCell>
                    <TableCell align="center">Hemen Çıkma Oranı</TableCell>
                    <TableCell align="center">Ortalama Süre</TableCell>
                    <TableCell align="center">Kullanıcı Başı Oturum</TableCell>
                    <TableCell align="center">Kazanç</TableCell>
                    <TableCell align="center">Eklenen Video</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analytics}
                </TableBody>
              </Table>
            </Paper>
          </React.Fragment>
        );
        break;


      default:
        tabContent = null;
        break;
    }


    return (
      <Grid container spacing={24}>

        {loading}
        {error}
        <Grid item xs={12} sm={12} lg={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.activeTab}
              onChange={this.changeTabHandle}
              variant="fullWidth"
              scrollButtons="auto"
              indicatorColor="secondary"
            >

              <Tab icon={<AttachMoney />} label="EXOCLICK" />
              <Tab icon={<Equalizer />} label="ANALYTICS" />
            </Tabs>
          </AppBar>

          {tabContent}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeDomain: state.domain.activeDomain
  };
};

const mapStateToDispatch = dispatch => {
  return {
    onChangeDomain: () => dispatch(actions.saveSettings())
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Statistics); 