import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Visibility from "@material-ui/icons/Visibility";
import CloudUpload from "@material-ui/icons/CloudUpload";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SpeakingUrl from "speakingurl";
import Badge from "@material-ui/core/Badge";
import SelectPhoto from "../../../components/SelectPhoto/SelectPhoto";
import axios from "../../../axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Select from "../../../components/Select/Select";

import Loading from "../../../components/Loading/Loading";
import Snackbar from "@material-ui/core/Snackbar";
import * as actions from "../../../store/actions";

const statuses = [
    {
        label: "Aktif",
        value: 1
    },
    {
        label: "Pasif",
        value: 0
    }
];

class PornstarEdit extends React.Component {
    state = {
        imageOpen: false,
        deleteDialog: false,
        photoSelect: false,
        imageOpenUrl: null,
        loading: null,
        error: null,
        seo: {
            titleLimit: 60,
            descriptionLimit: 230
        },
        pornstar: {
            id: null,
            title: "",
            slug: "",
            description: "",
            seoTitle: "",
            seoDescription: "",
            isActive: false,
            status: {},
            videosCount: 0,
            photos: [],
            cover: null,
            differentSlug: true,
            coverChanged: false
        },
        redirect: {
            url: null,
            withUpdate: null
        },
        videoCounts: []
    };

    open = (name, value) => {
        if (name === "imageOpen") {
            this.setState({ [name]: true, imageOpenUrl: value });
        } else {
            this.setState({ [name]: true });
        }

    };

    close = (name) => {
        this.setState({ [name]: false });
    };

    inputChangeHandler = (name, val) => {
        let pornstar = { ...this.state.pornstar, [name]: val };
        if (name === "cover") {
            pornstar.coverChanged = true;
        }

        this.setState({
            pornstar: pornstar
        });
    };

    seoChangeHandler = (name, val) => {
        let seo = { ...this.state.seo };
        let inputName = "";
        if (name === "title") {
            if (seo.titleLimit < val.length) {
                return false;
            }
            inputName = "seoTitle";
        } else if (name === "description") {
            if (seo.descriptionLimit < val.length) {
                return false;
            }
            inputName = "seoDescription";
        }

        this.setState({
            seo: seo
        });
        this.inputChangeHandler(inputName, val);

    };

    titleChangeHandler = (name, value) => {
        this.inputChangeHandler("title", value);
        if (!this.state.pornstar.differentSlug) {
            const slug = SpeakingUrl(value, {
                custom: {
                    "ö": "o",
                    "ü": "u"
                }
            });
            setTimeout(() => {
                this.inputChangeHandler("slug", slug);
            }, 5);
        }

    };

    slugChangeHandler = (name, value) => {
        this.inputChangeHandler(name, value);
        setTimeout(() => {
            this.inputChangeHandler("differentSlug", true);
        }, 5);
    };


    getPornstar = () => {
        this.setState({ loading: true });
        const domainID = this.props.activeDomain.id;
        const id = this.props.match.params.id;

        const postData = {
            domainID: domainID,
            id: id
        };

        axios.post("pornstar/getPornstar", postData)
            .then(response => {

                this.setState({
                    loading: false,
                    pornstar: {
                        ...this.state.pornstar,
                        id: response.data.id,
                        title: response.data.title,
                        slug: response.data.slug,
                        description: response.data.description ? response.data.description : "",
                        seoTitle: response.data.seoTitle ? response.data.seoTitle : "",
                        seoDescription: response.data.seoDescription ? response.data.seoDescription : "",
                        isActive: response.data.isActive,
                        videosCount: response.data.videosCount,
                        photos: response.data.photos,
                        cover: response.data.cover,
                        status: response.data.isActive ? statuses[0] : statuses[1]
                    }

                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    redirect: {
                        url: "/pornstars",
                        withUpdate: null
                    }
                });
            });
    };

    uploadInputChangeHandler = e => {

        const formData = new FormData();
        formData.append("photo", e.target.files[0]);
        formData.append("id", this.props.match.params.id);
        this.setState({
            loading: true
        });

        axios.post("/pornstar/upload-photo", formData)
            .then(response => {
                let pornstar = { ...this.state.pornstar };
                pornstar.photos.push(response.data.url);
                this.setState({
                    pornstar: pornstar,
                    loading: false
                });
            });

    };

    updateRequest = () => {
        this.setState({
            loading: true
        });

        const postData = this.state.pornstar;
        postData.domainID = this.props.activeDomain.id;

        axios.post("pornstar/update", postData)
            .then(response => {
                this.props.refreshPornstars();
                this.setState({
                    loading: false,
                    redirect: {
                        url: "/pornstars"
                    }
                });

            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    getVideoCounts = () => {
        this.setState({
            loading: true
        });

        axios.post("pornstar/get-video-counts", {
            id: this.state.pornstar.id,
            domainID: this.props.activeDomain.id
        })
            .then(response => {
                this.setState({
                    videoCounts: response.data.videoCounts,
                    loading: false
                });

            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    setDMCA = (type) => {
        let confirm;
        if (type === "single") {
            confirm = window.confirm("Aktif domaindeki bu yıldıza ait tüm videolar DMCA olarak işaretlenecektir. Onaylıyor musunuz?");
        } else {
            confirm = window.confirm("Bu yıldızın tüm domainlerdeki videoları DMCA olarak işaretlenecektir. Onaylıyor musunuz?");
        }

        if (!confirm) {
            return false;
        }

        this.setState({
            loading: true
        });
        axios.post("pornstar/set-dmca", {
            id: this.state.pornstar.id,
            domainID: this.props.activeDomain.id,
            type: type
        })
            .then(response => {
                this.setState({
                    loading: false
                });
                this.getVideoCounts();

            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    componentDidMount() {
        if (this.props.activeDomain.id !== null) {
            this.getPornstar();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && this.props.activeDomain.id !== prevProps.activeDomain.id) {

            this.getPornstar();
        }
    }

    render() {
        const redirect = this.state.redirect.url ? <Redirect to={{
            pathname: this.state.redirect.url,
            state: {
                update: this.state.redirect.withUpdate
            }
        }} /> : null;

        const photos = this.state.pornstar.photos.map(photo => {
            return <SelectPhoto total={this.state.pornstar.photos.length} key={photo} img={photo}
                                checked={photo === this.state.pornstar.cover} checkClick={() => {
                this.inputChangeHandler("cover", photo);
            }} showClick={() => {
                this.open("imageOpen", photo);
            }} />;
        });

        let photoSelectContent = null;
        let photoSelectFullWidth = true;

        const loading = this.state.loading ? <Loading size={100} /> : null;
        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        "className": "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        if (this.state.pornstar.photos.length > 0) {
            photoSelectContent = (
                <React.Fragment>
                    <Grid item xs={12} sm={9} xl={10}>
                        <Grid container spacing={24}>
                            {photos}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="PhotoUploadButton">
                        <input id="photoUploadInput" accept="image/*" onChange={this.uploadInputChangeHandler}
                               type="file"
                               style={{ position: "absolute", top: "-9999px" }} />
                        <label htmlFor="photoUploadInput">
                            <Button variant="contained" component="span" color="default">
                                FARKLI FOTOĞRAF YÜKLE&nbsp;&nbsp;
                                <CloudUpload />
                            </Button>
                        </label>
                    </Grid>
                </React.Fragment>
            );
        } else {
            photoSelectFullWidth = false;
            photoSelectContent = (
                <React.Fragment>
                    <Grid item xs={12} sm={12} xl={12} className="PhotoUploadButton">
                        <input id="photoUploadInput" accept="image/*" onChange={this.uploadInputChangeHandler}
                               type="file"
                               style={{ position: "absolute", top: "-9999px" }} />
                        <label htmlFor="photoUploadInput">
                            <Button variant="contained" component="span" color="default">
                                FOTOĞRAF YÜKLE&nbsp;&nbsp;
                                <CloudUpload />
                            </Button>
                        </label>
                    </Grid>
                </React.Fragment>
            );
        }

        let videoCounts;
        if (this.state.videoCounts.length > 0) {
            videoCounts = this.state.videoCounts.map(videoCount => {
                return <TableRow>
                    <TableCell className="th">{videoCount.site_name}:</TableCell>
                    <TableCell>{videoCount.total}</TableCell>
                </TableRow>;
            });
            videoCounts =
                <React.Fragment>
                    <Paper className="LeftSide" elevation={1} square>
                        <Table>
                            <TableBody>
                                {videoCounts}
                            </TableBody>
                        </Table>

                    </Paper>
                    <Button style={{ display: "block", width: "100%", marginTop: "15px", marginBottom: "15px" }}
                            onClick={() => this.setDMCA("single")}
                            variant="contained" color="danger">
                        Sadece <em>{this.props.activeDomain.title}</em> Sitesinden Kaldır
                    </Button>
                    <Button style={{ display: "block", width: "100%", marginTop: "15px", marginBottom: "15px" }}
                            onClick={() => this.setDMCA("all")}
                            variant="contained" color="secondary">
                        TÜM SİTELERDEN KALDIR
                    </Button>
                </React.Fragment>;
        }

        return (
            <div className="GeneralDetails">
                {redirect}
                {loading}
                {error}
                <Dialog
                    open={this.state.imageOpen}
                    onClose={() => {
                        this.close("imageOpen");
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogContent>
                        <img src={this.state.imageOpenUrl} style={{ maxWidth: "100%" }} alt="" />
                    </DialogContent>

                </Dialog>

                <Dialog
                    open={this.state.photoSelect}
                    onClose={() => {
                        this.close("photoSelect");
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={photoSelectFullWidth}
                    maxWidth={false}>

                    <DialogContent>
                        <Grid container spacing={24}>
                            {photoSelectContent}
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={1} xl={2}></Grid>
                    <Grid item xs={12} sm={4} xl={3}>
                        <Paper className="LeftSide" elevation={1} square>
                            <div className="Cover">
                                <img src={this.state.pornstar.cover} alt={this.state.pornstar.title} />
                                <div className="Buttons">
                                    <Visibility onClick={() => this.open("imageOpen", this.state.pornstar.cover)} />
                                    <Badge onClick={() => {
                                        this.open("photoSelect");
                                    }} className="PhotoLibraryBadge" badgeContent={this.state.pornstar.photos.length}
                                           color="primary"
                                           style={{ top: "-1px" }}>
                                        <PhotoLibrary />
                                    </Badge>
                                </div>
                                <div className="DarkLayer"></div>
                            </div>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="th">Kanal Adı:</TableCell>
                                        <TableCell>{this.state.pornstar.title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="th">Video Sayısı:</TableCell>
                                        <TableCell>{this.state.pornstar.videosCount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="th">Durum:</TableCell>
                                        <TableCell>{this.state.pornstar.isActive ? "Aktif" : "Pasif"}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </Paper>

                        <Paper className="LeftSide" elevation={1} square>
                            <Button style={{ display: "block", width: "100%", marginTop: "15px", marginBottom: "15px" }}
                                    onClick={this.getVideoCounts}
                                    variant="contained" color="warning">
                                VİDEO SAYISI SORGULA
                            </Button>
                        </Paper>

                        {videoCounts}

                    </Grid>
                    <Grid item xs={12} sm={6} xl={5}>
                        <Paper className="GeneralPaper" elevation={1} square>
                            <TextField
                                fullWidth
                                label="Başlık"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={(e) => this.titleChangeHandler("title", e.target.value)}
                                value={this.state.pornstar.title}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Link Yapısı"
                                value={this.state.pornstar.slug}
                                onChange={(e) => this.slugChangeHandler("slug", e.target.value)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Paper>

                        <Paper className="GeneralPaper" elevation={1} square>
                            <Select
                                changed={(val) => this.inputChangeHandler("status", val)}
                                placeholder="Durum"
                                options={statuses}
                                value={this.state.pornstar.status}
                                label="Durum"
                                className="AddVideoSelect"
                            />
                            <TextField
                                fullWidth
                                label="Açıklama"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                multiline
                                rows={5}
                                rowsMax={10}
                                value={this.state.pornstar.description}
                                onChange={(e) => this.inputChangeHandler("description", e.target.value)}
                            />
                        </Paper>
                        <Paper className="GeneralPaper" elevation={1} square>
                            <TextField
                                fullWidth
                                label="Seo Başlığı"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={this.state.pornstar.seoTitle}
                                onChange={(e) => this.seoChangeHandler("title", e.target.value)}
                                helperText={this.state.pornstar.seoTitle.length + " / " + this.state.seo.titleLimit}
                            />
                            <TextField
                                fullWidth
                                label="Seo Açıklaması"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                multiline
                                rows={3}
                                value={this.state.pornstar.seoDescription}
                                margin="normal"
                                onChange={(e) => this.seoChangeHandler("description", e.target.value)}
                                helperText={this.state.pornstar.seoDescription.length + " / " + this.state.seo.descriptionLimit}
                            />
                        </Paper>
                        <Button style={{ display: "block", width: "100%", marginTop: "15px" }}
                                onClick={this.updateRequest}
                                variant="contained" color="primary">
                            GÜNCELLE
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={1} xl={2}></Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refreshPornstars: () => dispatch(actions.savePornstars())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PornstarEdit);