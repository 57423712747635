import * as actionTypes from './actionTypes';
import * as actions from './index';

export const changeDefaultDomain = (id, domain, title) => {

    return dispatch => {
        localStorage.setItem('default_domain', JSON.stringify({
            id: id,
            domain: domain,
            title: title
        }));
        dispatch(saveDefaultDomain(id, domain, title));

        // dispatch(actions.saveCategories());
        // dispatch(actions.savePornstars());
        // dispatch(actions.saveChannels());
        // dispatch(actions.saveTags());
        // dispatch(actions.saveComments());
        dispatch(actions.saveSettings());
        // dispatch(actions.saveConfigs());
    }
}

export const saveDefaultDomain = (id, domain, title) => {

    return {
        type: actionTypes.CHANGE_DEFAULT_DOMAIN,
        id: id,
        title: title,
        domain: domain,
    }
}

export const saveDomains = (domains) => {
    return {
        type: actionTypes.SAVE_DOMAINS,
        domains: domains
    }
}

export const saveDomainWithLocalStorage = () => {
    return dispatch => {
        const defaultDomain = JSON.parse(localStorage.default_domain);
        // console.log(defaultDomain);
        dispatch(changeDefaultDomain(defaultDomain.id, defaultDomain.domain, defaultDomain.title));
    }
}