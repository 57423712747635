import React from 'react';

import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import Delete from '@material-ui/icons/Delete';

const comment = (props) => {
    let checkbox = null;
    if (props.is_active) {
        checkbox = (
            <CheckBox onClick={() => { props.setPassive(props.id) }} />
        );
    } else {
        checkbox = (
            <CheckBoxOutlineBlank onClick={() => { props.setActive(props.id) }} />
        );
    }

    return (
        <tr className="CommentRow">
            <td className="VideoDetails">
                <a href={props.video.url} target="_blank" rel="noopener noreferrer">
                    <img src={props.video.cover} alt={props.video.title} />
                </a>
            </td>
            <td className="VideoTitle">
                <a href={props.video.url} target="_blank" rel="noopener noreferrer">
                    {props.video.title}
                </a>
            </td>
            <td className="Comment">
                <p><strong>Yazar: </strong> {props.author}</p>
                {props.comment}
            </td>
            <td className="Date">
                {props.date}
            </td>
            <td className="ActionButtons">
                {checkbox}
                <Delete className="DeleteIcon" onClick={() => { props.delete(props.id) }} />
            </td>
        </tr >
    );
}

export default comment;