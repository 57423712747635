import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import Loading from "../../components/Loading/Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "../../axios";

import * as actions from "../../store/actions";
import Edit from "@material-ui/icons/Edit";
import { Add, Done } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";

class Blog extends React.Component {
    state = {
        loading: false,
        error: null,
        deleteDialog: false,
        deleteID: null,
        contents: []
    };

    componentDidMount() {
        if (this.props.activeDomain.id !== null) {
            this.getContents();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeDomain.id !== prevProps.activeDomain.id) {
            this.getContents();
        }
    }

    getContents() {
        this.setState({
            loading: true
        });

        axios.post("blog/get", {
            domainID: this.props.activeDomain.id
        })
            .then(response => {
                this.setState({
                    loading: false,
                    contents: response.data
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    }

    deleteVideo = () => {
        this.setState({
            loading: true
        });
        axios.post("blog/delete", {
            domainID: this.props.activeDomain.id,
            content_id: this.state.deleteID
        })
            .then(response => {
                this.setState({
                    loading: false,
                    deleteDialog: false
                });
                this.getContents();
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };


    errorCloseHandler = () => {
        this.setState({
            error: null
        });
    };

    render() {
        let loading = null;

        if (this.state.loading) {
            loading = <Loading size={100} />;
        }

        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        "className": "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        let tabContent;
        let contents = null;

        if (this.state.contents.length > 0) {
            contents = this.state.contents.map(content => {
                let categories = content.categories.map(category => {
                    return category.name;
                }).join(", ");
                let icon = null;
                if (!content.is_active) {
                    icon = <Edit />;
                } else {
                    icon = <Done color={"primary"} />;
                }
                if (!categories) {
                    categories = "-";
                }


                return (
                    <TableRow key={content.id}>
                        <TableCell><span style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px"
                        }}>{icon} {content.title}</span></TableCell>
                        <TableCell>{categories}</TableCell>
                        <TableCell>
                            <div style={{
                                display: "flex",
                                gap: "10px"
                            }}>
                                <Link to={"/blog/edit/" + content.id}>
                                    <Button variant="contained" color="primary">
                                        <Edit />
                                    </Button>
                                </Link>

                                <Button variant="contained" color="secondary" onClick={() => {
                                    this.setState({
                                        deleteDialog: true,
                                        deleteID: content.id
                                    });
                                }}>
                                    <Delete />
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                );
            });
        }
        tabContent = (
            <React.Fragment>
                <Dialog
                    open={this.state.deleteDialog}
                    onClose={() => {
                        this.setState({
                            deleteDialog: false
                        });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth>
                    <DialogContent>
                        <p>Blog içeriği silinecektir. </p>
                        <p><strong>Onaylıyor musunuz?</strong></p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                deleteDialog: false
                            });
                        }} color="primary">
                            İptal
                        </Button>
                        <Button onClick={this.deleteVideo} color="secondary" variant="contained">
                            SİL
                        </Button>
                    </DialogActions>
                </Dialog>
                <Paper square className="GeneralPaper ">
                    <Link to={"/blog/add"} style={{ marginRight: "10px" }}>
                        <Button variant="contained" color="primary">
                            <Add /> Yeni Ekle
                        </Button>
                    </Link>

                    <Link to={"/blog/categories"}>
                        <Button variant="contained">
                            Kategoriler
                        </Button>
                    </Link>
                    <Table className="ExoclickTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Başlık</TableCell>
                                <TableCell>Kategoriler</TableCell>
                                <TableCell>İşlem</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        );


        return (
            <Grid container spacing={24}>

                {loading}
                {error}
                <Grid item xs={12} sm={12} lg={12}>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} xl={12}>
                            {tabContent}
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                </Grid>
            </Grid>
        );
    }
}


const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
        activeUser: state.auth.user
    };
};

const mapStateToDispatch = dispatch => {
    return {
        onChangeDomain: () => dispatch(actions.saveSettings())
    };
};

export default connect(mapStateToProps, mapStateToDispatch)(Blog);