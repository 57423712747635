import * as actionTypes from '../actions/actionTypes';

const initialState = {
    activeDomain: {
        id: null,
        title: "",
        domain: ""
    },
    domains: [],

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_DEFAULT_DOMAIN:
            return {
                ...state,
                activeDomain: {
                    ...state.activeDomain,
                    id: action.id,
                    title: action.title,
                    domain: action.domain,
                },
            }
        case actionTypes.SAVE_DOMAINS:
            return {
                ...state,
                domains: action.domains
            }
        default:
            return state;
    }
}

export default reducer;