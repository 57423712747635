/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";

import dashboardStyle from "../../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

// import image from "assets/img/sidebar-bg.jpg";
import logo from "../../assets/img/reactlogo.png";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

function importAll(r) {
    return r.keys().map(r);
}


const images = importAll(require.context("../../assets/img/sidebar-images", false, /\.(png|jpe?g|svg)$/));

const switchRoutes = (props) => (
    <Switch>
        {dashboardRoutes.map((prop, key) => {
            if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.to} key={key} />;
            } else {
                return <Route path={prop.path} component={prop.component} key={key} />;
            }


        })}
    </Switch>
);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            image: images[Math.floor(Math.random() * images.length)],
            image: null
        };
        this.resizeFunction = this.resizeFunction.bind(this);
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    getRoute() {
        return this.props.location.pathname !== "/maps";
    }

    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }

    componentDidMount() {
        // console.log("did-mount");
        // if (navigator.platform.indexOf("Win") > -1) {
        //   const ps = new PerfectScrollbar(this.refs.mainPanel);
        // }
        // window.addEventListener("resize", this.resizeFunction);
    }

    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            // if (!this.refs) {
            //     this.refs = {};
            // }
            //
            // if (!this.refs.mainPanel) {
            //     this.refs.mainPanel = {};
            // }
            // this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }

    render() {

        const { classes, ...rest } = this.props;
        console.log(this.props);
        if (this.props.authTokenFailed) {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
        }
        if (!this.props.isAuth) {
            if (localStorage.token) {
                this.props.onAuthWithToken(localStorage.token);
                return null;
            } else {
                return <Redirect to={{
                    pathname: "/auth/login",
                    state: { referrer: this.props.location.pathname }
                }} key="auth" />;
            }

        }

        return (

            <div className={classes.wrapper}>
                <Sidebar
                    routes={dashboardRoutes}
                    logoText={"Admin Panel"}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="blue"
                    {...rest}
                />
                <div className={classes.mainPanel} ref="mainPanel" id="scrolledContent">
                    <Header
                        routes={dashboardRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                    {this.getRoute() ? (
                        <div className={classes.content}>
                            <div className={classes.container}>{switchRoutes(this.props)}</div>
                        </div>
                    ) : (
                        <div className={classes.map}>{switchRoutes(this.props)}</div>
                    )}
                    {this.getRoute() ? <Footer /> : null}
                </div>
            </div>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authTokenFailed: state.auth.auth_token_failed
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthWithToken: token => dispatch(actions.authWithToken(token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(App));
