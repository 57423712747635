import React from 'react';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { Redirect } from 'react-router-dom';


import { connect } from 'react-redux';


import * as actions from '../../store/actions';

class Login extends React.Component {

    state = {
        email: null,
        password: null,
        warning: null,
    }

    loginHandler = (e) => {
        e.preventDefault();

        if (!this.state.email || !this.state.password) {
            this.setState({
                warning: "Tüm alanları doldurunuz."
            });
        } else {
            this.props.onAuth(this.state.email, this.state.password);
        }


    }

    inputChangedHandler = (event, name) => {
        this.setState({
            [name]: event.target.value
        });
    }

    snackbarCloseHandler = () => {
        this.setState({
            warning: null,
        });
    }

    render() {
        if (this.props.isAuth) {
            const redirectUrl = "/videos";
            return <Redirect to={redirectUrl} />
        }

        const err = this.props.error ? this.props.error : this.state.warning;
        return (
            <div className="LoginPage">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={err !== null}
                    autoHideDuration={5000}
                    onClose={this.snackbarCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar warning"
                    }}

                    message={<span id="message-id">{err}</span>}
                />
                <GridContainer>
                    <GridItem xs={false} sm={3} md={4}></GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <h1>Login</h1>
                        <form onSubmit={this.loginHandler}>
                            <TextField
                                id="email"
                                label="Email"
                                margin="normal"
                                fullWidth
                                type="email"
                                onChange={(event) => this.inputChangedHandler(event, "email")}
                            />
                            <TextField
                                type="password"
                                id="password"
                                label="Password"
                                margin="normal"
                                fullWidth
                                onChange={(event) => this.inputChangedHandler(event, "password")}
                            />
                            <Button type="submit" className="loginButton" variant="contained" color="primary" value="1" >
                                Login
                            </Button>
                        </form>
                    </GridItem>
                    <GridItem xs={false} sm={3} md={4}></GridItem>
                </GridContainer>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        error: state.auth.error,
        isAuth: state.auth.token !== null,
    }
}

const mapDispathToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onAuthWithToken: token => dispatch(actions.authWithToken(token)),
    }
}


export default connect(mapStateToProps, mapDispathToProps)(Login);