import * as actionTypes from '../actions/actionTypes';


const initialState = {
    pornstars: [],
    filteredPornstars: [],
    loading: null,
    error: null,
    pagination: {
        limit: 48,
        offset: 0,
        total: 0,
    },
    filters: {
        title: "",
        status: null,
        sort: null,
    },
    filtersExpanded: false,
}


const pornstars = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_PORNSTARS:
            return {
                ...state,
                pornstars: action.pornstars,
                filteredPornstars: action.pornstars,
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.pornstars.length,
                },
            }
        case actionTypes.SAVE_PORNSTARS_START:

            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.CHANGE_PORNSTAR_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    offset: action.offset,
                }
            }

        case actionTypes.SAVE_FILTERED_PORNSTARS:

            return {
                ...state,
                filteredPornstars: action.pornstars,
                pagination: {
                    ...state.pagination,
                    total: action.pornstars.length,
                    offset: 0,
                },
                filters: action.filters,
            }
        case actionTypes.RESET_ALL_PORNSTARS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    title: "",
                    status: null,
                    sort: null,
                }
            }
        case actionTypes.CHANGE_PORNSTAR_FILTERS_EXPANDED:
            return {
                ...state,
                filtersExpanded: action.expanded
            }
        default:
            return state;
    }
}

export default pornstars;