import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import React from 'react';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import CreatableSelect from 'react-select/lib/Creatable';
import axios from '../../axios';


const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

function Control(props) {
    return (
        <TextField

            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,

    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (
        inputValue.trim().length === 0 ||
        selectOptions.find(option => option.label === inputValue)
    ) {
        return false;
    }
    return true;
}

const loadOptions = (inputValue, callback, ajaxUrl, domainID) => {
    axios.post(ajaxUrl, {
        q: inputValue,
        domainID: domainID
    })
        .then(response => {
            callback(response.data);
        });

}

const select = (props) => {
    const { classes, theme } = props;

    const selectStyles = {
        input: base => ({
            ...base,
            minWidth: props.minWidth || "auto",
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    let label = "";
    if (props.label) {
        label = {
            label: props.label,
            helperText: props.helperText,
            InputLabelProps: {
                shrink: true,
            },
        }
    }
    const multi = props.multi ? true : false;
    const clearable = props.notClearable ? false : true;

    let selectHTML = "";



    if (props.ajaxUrl) {
        if (props.creatable) {
            selectHTML = (
                <NoSsr>
                    <AsyncCreatableSelect
                        classes={classes}
                        styles={selectStyles}
                        // options={props.options}
                        // cacheOptions
                        loadOptions={(v, c) => loadOptions(v, c, props.ajaxUrl, props.domainID)}
                        // loadOptions={(v) => promiseOptions(v, props.options)}
                        // defaultOptions
                        components={components}
                        value={props.value}
                        onChange={props.changed}
                        placeholder={props.placeholder}
                        textFieldProps={label}
                        isMulti={multi}
                        className={props.className}
                        closeMenuOnSelect={!multi}
                        loadingMessage={() => {
                            return "Yükleniyor..";
                        }}
                        noOptionsMessage={(input) => {

                            if (input.inputValue) {
                                return "Seçenek bulunamadı.";
                            } else {
                                return "Aramaya başlamak için bir şeyler yaz";
                            }
                        }}
                        formatCreateLabel={(a) => {
                            return a + " (Yeni Oluştur)";
                        }}
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                            const isNotDuplicated = !selectOptions
                                .map(option => option.label)
                                .includes(inputValue);
                            const isNotEmpty = inputValue !== '';
                            return isNotEmpty && isNotDuplicated;
                        }}
                        getNewOptionData={(inputValue, optionLabel) => {
                            return {
                                label: inputValue + " (Yeni)",
                                value: inputValue,
                                __isNew__: true
                            }
                        }}

                    />
                </NoSsr>
            )
        } else {
            selectHTML = (
                <NoSsr>
                    <AsyncSelect
                        classes={classes}
                        styles={selectStyles}
                        // options={props.options}
                        // cacheOptions
                        loadOptions={(v, c) => loadOptions(v, c, props.ajaxUrl, props.domainID)}
                        // defaultOptions
                        components={components}
                        value={props.value}
                        onChange={props.changed}
                        placeholder={props.placeholder}
                        textFieldProps={label}
                        isMulti={multi}
                        className={props.className}
                        closeMenuOnSelect={!multi}
                        isClearable={clearable}
                        loadingMessage={() => {
                            return "Yükleniyor..";
                        }}
                        noOptionsMessage={(input) => {

                            if (input.inputValue) {
                                return "Seçenek bulunamadı.";
                            } else {
                                return "Aramaya başlamak için bir şeyler yaz";
                            }

                        }}
                    />
                </NoSsr>
            )
        }

    } else if (props.creatable) {
        selectHTML = (
            <NoSsr>
                <CreatableSelect
                    classes={classes}
                    styles={selectStyles}
                    options={props.options}
                    components={components}
                    value={props.value}
                    onChange={props.changed}
                    placeholder={props.placeholder}
                    textFieldProps={label}
                    isMulti={multi}
                    className={props.className}
                    closeMenuOnSelect={!multi}
                    formatCreateLabel={(a) => {
                        return a + " (Yeni Oluştur)";
                    }}
                    isValidNewOption={isValidNewOption}
                    isClearable={clearable}
                    noOptionsMessage={() => {
                        return "Seçenek bulunamadı.";
                    }}
                />

            </NoSsr>
        );
    } else {
        selectHTML = (
            <NoSsr>
                <ReactSelect
                    classes={classes}
                    styles={selectStyles}
                    options={props.options}
                    components={components}
                    value={props.value}
                    onChange={props.changed}
                    placeholder={props.placeholder}
                    textFieldProps={label}
                    isMulti={multi}
                    className={props.className}
                    closeMenuOnSelect={!multi}
                    isClearable={clearable}
                    noOptionsMessage={() => {
                        return "Seçenek bulunamadı.";
                    }}
                />

            </NoSsr>
        );
    }

    return selectHTML;
}



export default withStyles(styles, { withTheme: true })(select);