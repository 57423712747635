import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';

import { Link } from 'react-router-dom';

const category = (props) => {

    let classes = ['GeneralList'];

    if (!props.isActive) {
        classes.push("Passive");
    }


    return (
        <Grid item xs={12} sm={3} lg={2} xl={1} >
            <Card className={classes.join(" ")}>
                <Link to={"edit-category/" + props.id}>
                    <CardActionArea className="GeneralListActionArea">
                        <div className="Image">
                            <div className="Transparent"></div>
                            <img alt={props.title} src={props.photo} />
                            <span className="VideosCount">
                                {props.videosCount}
                            </span>
                        </div>
                        <div className="Info">
                            <div className="Title">
                                <h2>{props.title}</h2>
                            </div>
                            {/* 
                            <div className="Site">
                                {props.site}
                            </div>
                            */}
                        </div>

                    </CardActionArea>
                </Link>
            </Card>
        </Grid>
    );
}

export default category;