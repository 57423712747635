import React from 'react';

class JwPlayer extends React.Component {

    state = {
        loaded: false,
    }
    initialize = ({ player, playerOpts }) => {
        player.setup(playerOpts);
    }
    loadCallback = () => {
        const player = window.jwplayer(this.props.id);

        const height = this.props.height ? this.props.height : 400;
        const image = this.props.image ? this.props.image : null;
        const sources = this.props.sources;

        const playerOpts = {
            height: height,
            image: image,
            preload: "auto",
            // type: "hls",
            sources: sources
        };
        player.setup(playerOpts);

    }

    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://content.jwplatform.com/libraries/CwFqONv4.js?v=2";
        script.async = true;
        script.onload = this.loadCallback;

        document.body.appendChild(script);


    }

    render() {

        return <div id={this.props.id}></div>;

    }
}


export default JwPlayer;