import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const saveComments = () => {
    return (dispatch, getState) => {
        dispatch(saveCommentsStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
        }
        axios.post("comment/getComments", postData)
            .then(response => {
                dispatch(saveCommentsToState(response.data));
                dispatch(filterComments());
                dispatch(changeCommentPagination(getState().comment.pagination.offset));
            })
    }
}

export const saveCommentsToState = (comments) => {
    return {
        type: actionTypes.SAVE_COMMENTS,
        comments: comments,
    }
}

export const changeCommentPagination = (offset) => {
    return {
        type: actionTypes.CHANGE_COMMENT_PAGINATION,
        offset: offset,
    }
}

export const saveCommentsStart = () => {
    return {
        type: actionTypes.SAVE_COMMENTS_START,
    }
}

export const filterComments = (payload) => {
    if (!payload) payload = {};

    return (dispatch, getState) => {
        payload.status = payload && payload.status ? payload.status : getState().comment.filters.status;
        payload.title = payload && payload.title ? payload.title : getState().comment.filters.title;
        let comments = getState().comment.comments;
        if (payload.status) {
            comments = comments.filter(comment => {
                return comment.is_active === (payload.status.value === 1)
            });
        }

        if (payload.title) {
            comments = comments.filter(comment => {
                return comment.comment.toLowerCase().indexOf(payload.title.toLowerCase()) !== -1;
            });
        }

        dispatch(saveFilteredCategories(comments, payload));
    }
}

export const saveFilteredCategories = (comments, filters) => {
    return {
        type: actionTypes.SAVE_FILTERED_COMMENTS,
        comments: comments,
        filters: filters,
    }
}

export const resetAllCommentsFilters = () => {
    return {
        type: actionTypes.RESET_ALL_COMMENTS_FILTERS,
    }
}


export const changeCommentFiltersExpanded = (expanded) => {
    return {
        type: actionTypes.CHANGE_COMMENT_FILTERS_EXPANDED,
        expanded: expanded,
    }
}

export const deleteComment = (id) => {
    return (dispatch, getState) => {
        dispatch(saveCommentsStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
            id: id,
        }
        axios.post("comment/delete", postData)
            .then(response => {
                dispatch(saveComments());
            })
    }
}

export const activateComment = (id) => {
    return (dispatch, getState) => {
        dispatch(saveCommentsStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
            id: id,
        }
        axios.post("comment/activate", postData)
            .then(response => {
                dispatch(saveComments());
            })
    }
}

export const deactivateComment = (id) => {
    return (dispatch, getState) => {
        dispatch(saveCommentsStart());
        const postData = {
            domainID: getState().domain.activeDomain.id,
            id: id,
        }
        axios.post("comment/deactivate", postData)
            .then(response => {
                dispatch(saveComments());
            })
    }
}