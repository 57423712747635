import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Select from "../../components/Select/Select";

import axios from "../../axios";
import Loading from "../../components/Loading/Loading";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

const current_datetime = new Date();
const today = current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate());
const nextYear = current_datetime.getFullYear() + 1 + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate());

class DraftToSchedule extends React.Component {
  state = {
    contents: {},
    inputs: {
      startDate: today,
      endDate: nextYear,
      dailyVideoCount: 0,
      dailyVideoCountMax: 0,
      draftVideoCount: "Yükleniyor..",
      day: 1,
      startHour: "",
      endHour: "",
      channel: null,
      pornstar: null
    }
  };


  inputChangeHandler = (name, val) => {
    let inputs = { ...this.state.inputs, [name]: val };

    this.setState({
      inputs: inputs
    });

  };

  getScheduledContents = () => {
    this.setState({
      loading: true
    });
    axios.post("draft-to-schedule/get-scheduled-contents", {
      domainID: this.props.activeDomain.id
    })
      .then(response => {
        this.setState({
          loading: false,
          contents: response.data
        });
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  };

  updateDraftVideoCount = () => {
    this.setState({
      loading: true
    });
    const postData = {
      domainID: this.props.activeDomain.id
    };

    axios.post("draft-to-schedule/getDraftVideoCount", postData)
      .then(response => {
        this.setState({
          loading: false,
          inputs: {
            ...this.state.inputs,
            draftVideoCount: response.data.draftCount
          }
        });

      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  };


  submitForm = () => {
    this.setState({
      loading: true
    });

    const postData = this.state.inputs;
    postData.domainID = this.props.activeDomain.id;

    console.log(postData);
    this.setState({
      loading: false
    });

    axios.post("draft-to-schedule/submit", postData)
      .then(response => {
        window.location.reload();
        this.setState({
          loading: false
        });

      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  };

  getChannels() {
    this.setState({
      loading: true
    });
    const postData = {
      domainID: this.props.activeDomain.id
    };

    axios.post("draft-to-schedule/getDraftChannels", postData)
      .then(response => {

        const channels = response.data.channels.map(channel => {
          return {
            label: channel.name + " (" + channel.draftCount + ")",
            value: channel.id
          };
        });

        this.setState({
          loading: false,
          channels: channels,
          inputs: {
            ...this.state.inputs,
            channel: null
          }
        });
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  }


  getPornstars() {
    this.setState({
      loading: true
    });
    const postData = {
      domainID: this.props.activeDomain.id
    };

    axios.post("draft-to-schedule/getDraftPornstars", postData)
      .then(response => {

        const pornstars = response.data.pornstars.map(pornstar => {
          return {
            label: pornstar.name + " (" + pornstar.draftCount + ")",
            value: pornstar.id
          };
        });

        this.setState({
          loading: false,
          pornstars: pornstars,
          inputs: {
            ...this.state.inputs,
            pornstar: null
          }
        });
      })
      .catch(err => {
        const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
        this.setState({
          error: msg,
          loading: false
        });
      });
  }

  componentDidMount() {

    if (this.props.activeDomain.id !== null) {
      this.updateDraftVideoCount();
      this.getChannels();
      this.getPornstars();
      this.getScheduledContents();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeDomain.id !== null && this.props.activeDomain.id !== prevProps.activeDomain.id) {
      this.updateDraftVideoCount();
      this.getChannels();
      this.getPornstars();
      this.getScheduledContents();
    }
  }

  render() {
    const loading = this.state.loading ? <Loading size={100} /> : null;
    let error = null;
    if (this.state.error) {
      error = (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.error !== null}
          autoHideDuration={5000}
          onClose={this.errorCloseHandler}
          ContentProps={{
            "aria-describedby": "message-id",
            "className": "snackbar danger"
          }}

          message={<span id="message-id">{this.state.error}</span>}
        />
      );
    }

    let contents = null;

    if (Object.keys(this.state.contents).length > 0) {
      contents = Object.keys(this.state.contents).map(date => {
        return (
          <TableRow key={date}>
            <TableCell><span
              style={{
                color: "red",
                fontWeight: "bold"
              }}>{date}</span> : <strong>{this.state.contents[date]}</strong></TableCell>

          </TableRow>
        );
      });
    }


    return (
      <div className="GeneralDetails">
        {loading}
        {error}

        <Grid container spacing={24}>

          <Grid item xs={12} sm={8} lg={8}>
            <Paper className="GeneralPaper" elevation={1} square>
              <TextField
                fullWidth
                label="Site Adresi"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.props.activeDomain.title}
                disabled
              />

              <TextField
                margin="normal"
                fullWidth
                label="Taslaktaki Video Adedi"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.inputs.draftVideoCount}
                disabled
              />

              <Select
                changed={(val) => this.inputChangeHandler("channel", val)}
                placeholder="Kanal"
                options={this.state.channels}
                value={this.state.inputs.channel}
                label="Kanal"
              />

              <Select
                changed={(val) => this.inputChangeHandler("pornstar", val)}
                placeholder="Pornstar"
                options={this.state.pornstars}
                value={this.state.inputs.pornstars}
                label="Pornstar"
                margin="normal"
              />

              <TextField
                margin="normal"
                id="date"
                label="Başlangıç Tarihi"
                type="date"
                fullWidth
                onChange={e => (this.inputChangeHandler("startDate", e.target.value))}
                helperText="Girilmezse bir sonraki günden itibaren zamanlamaya başlanır."
                inputProps={{
                  min: today
                }}
                InputLabelProps={{
                  shrink: true

                }}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Günlük Yayınlanacak Video Adedi (Min)"
                InputLabelProps={{
                  shrink: true
                }}
                // value={this.state.inputs.dailyVideoCount}
                onChange={e => (this.inputChangeHandler("dailyVideoCount", e.target.value))}
                type="number"
              />
              <TextField
                margin="normal"
                fullWidth
                label="Günlük Yayınlanacak Video Adedi (Max)"
                InputLabelProps={{
                  shrink: true
                }}
                // value={this.state.inputs.dailyVideoCount}
                onChange={e => (this.inputChangeHandler("dailyVideoCountMax", e.target.value))}
                type="number"
              />
              <TextField
                margin="normal"
                id="date2"
                label="Bitiş Tarihi (Opsiyonel)"
                type="date"
                fullWidth
                onChange={e => (this.inputChangeHandler("endDate", e.target.value))}
                helperText="Girilmezse günlük yayınlanacak video adedi, başlangıç tarihinden itibaren tüm taslak videoları tamamlanana kadar zamanlanır."
                inputProps={{
                  // min: today
                }}
                InputLabelProps={{
                  shrink: true

                }}
              />

              <TextField
                margin="normal"
                fullWidth
                label="Kaç Günde Bir Video Atılacak?"
                InputLabelProps={{
                  shrink: true
                }}
                helperText="Girilmezse ya da 1 girilirse her gün sırayla video atılır"
                // value={this.state.inputs.dailyVideoCount}
                onChange={e => (this.inputChangeHandler("day", e.target.value))}
                type="number"
              />
              <TextField
                margin="normal"
                fullWidth
                label="Saat Aralığı (Başlangıç) (0-23)"
                InputLabelProps={{
                  shrink: true
                }}
                helperText="Bütün videolar girilen saat aralığında atılır. Girilmezse 24 saate yayılır."
                // value={this.state.inputs.dailyVideoCount}
                onChange={e => (this.inputChangeHandler("startHour", e.target.value))}
                type="number"
                inputProps={{
                  min: 0,
                  max: 23
                }}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Saat Aralığı (Bitiş) (0-23)"
                InputLabelProps={{
                  shrink: true
                }}
                helperText="Bütün videolar girilen saat aralığında atılır. Girilmezse 24 saate yayılır."
                // value={this.state.inputs.dailyVideoCount}
                onChange={e => (this.inputChangeHandler("endHour", e.target.value))}
                type="number"
                inputProps={{
                  min: 0,
                  max: 23
                }}
              />
            </Paper>


            <Button style={{ display: "block", width: "100%", marginTop: "15px" }} onClick={this.submitForm}
                    variant="contained" color="primary">
              GÜNCELLE
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} xl={12}>
                <Paper square className="GeneralPaper ">

                  <Table className="ExoclickTable">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tarih : İçerik Sayısı</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contents}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeDomain: state.domain.activeDomain
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // refreshChannels: () => dispatch(actions.saveChannels()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftToSchedule);