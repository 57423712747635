import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '../../components/Select/Select';
import axios from '../../axios';

import * as actions from '../../store/actions';

class Payments extends React.Component {
    state = {
        users: [],
        loading: false,
        error: null,
        payments: [],
        inputs: {
            user: null,
            date: null,
            dollar: "",
            tl: "",
            rate: "",
        }
    }

    inputChangeHandler = (name, val) => {
        let inputs = { ...this.state.inputs, [name]: val };

        this.setState({
            inputs: inputs
        });
    }

    componentDidMount() {
        this.getUsers();
        this.getPayments();
    }

    getUsers() {
        this.setState({
            loading: true,
        });

        axios.post("payments/get-users")
            .then(response => {
                const users = response.data.map(user => {
                    return {
                        label: user.name,
                        value: user.id,
                    };
                });
                this.setState({
                    loading: false,
                    users: users
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }



    getPayments() {
        this.setState({
            loading: true,
        });

        axios.post("payments/get-payments")
            .then(response => {
                this.setState({
                    loading: false,
                    payments: response.data,
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }

    submitForm = () => {
        this.setState({
            loading: true,
        });

        const postData = this.state.inputs;

        axios.post("payments/add-payment", postData)
            .then(response => {
                this.getPayments();
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false,
                })
            });
    }


    errorCloseHandler = () => {
        this.setState({
            error: null,
        });
    }

    render() {
        let loading = null;

        if (this.state.loading) {
            loading = <Loading size={100} />;
        }

        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        let tabContent;
        let payments = null;

        if (this.props.activeUser.id !== 1) {
            return "Yetki hatası.";
        }

        if (this.state.payments.length > 0) {
            payments = this.state.payments.map(payment => {
                return (
                    <TableRow key={payment.id}>
                        <TableCell>{payment.user}</TableCell>
                        <TableCell align="right">{payment.dollar}</TableCell>
                        <TableCell align="right"><strong>{payment.tl}</strong></TableCell>
                        <TableCell align="right">{payment.exchange_rate}</TableCell>
                        <TableCell align="right">{payment.date}</TableCell>
                    </TableRow>
                )
            });
        }
        tabContent = (
            <React.Fragment>
                <Paper square className="GeneralPaper " >

                    <Table className="ExoclickTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Kime</TableCell>
                                <TableCell align="right">Dolar</TableCell>
                                <TableCell align="right">TL</TableCell>
                                <TableCell align="right">Kur</TableCell>
                                <TableCell align="right">Tarih</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payments}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        );


        return (
            <Grid container spacing={24}>

                {loading}
                {error}
                <Grid item xs={12} sm={12} lg={12}>
                    <Grid container spacing={24}>

                        <Grid item xs={12} sm={12} xl={5} >
                            <Paper className="GeneralPaper" elevation={1} square>

                                <Select
                                    changed={(val) => this.inputChangeHandler("user", val)}
                                    placeholder="Kime"
                                    options={this.state.users}
                                    value={this.state.inputs.user}
                                    label="Kime"
                                />

                                <TextField
                                    margin="normal"
                                    id="date"
                                    label="Tarih"
                                    type="date"
                                    fullWidth
                                    onChange={e => (this.inputChangeHandler("date", e.target.value))}
                                    helperText="Girilmezse o an olarak kaydedilir."
                                    InputLabelProps={{
                                        shrink: true,

                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Dolar"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => (this.inputChangeHandler("dollar", e.target.value))}
                                    type="number"
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="TL"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => (this.inputChangeHandler("tl", e.target.value))}
                                    type="number"
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Döviz Kuru"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => (this.inputChangeHandler("rate", e.target.value))}
                                    type="number"
                                />

                            </Paper>


                            <Button style={{ display: "block", width: "100%", marginTop: "15px" }} onClick={this.submitForm} variant="contained" color="primary" >
                                EKLE
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} xl={7} >
                            {tabContent}
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                </Grid>
            </Grid>
        );
    }
}



const mapStateToProps = state => {
    return {
        activeDomain: state.domain.activeDomain,
        activeUser: state.auth.user
    }
}

const mapStateToDispatch = dispatch => {
    return {
        onChangeDomain: () => dispatch(actions.saveSettings()),
    }
}

export default connect(mapStateToProps, mapStateToDispatch)(Payments); 